import {makeObservable, observable} from 'mobx';
import {createContext} from 'react';
import {api} from '../../services/Api';

export const realEstateClearState: RealEstateNew = {
    country: 'ES',
    province: '',
    client: '',
    municipality: null,
    realEstateType: '',
    price: null,
    status: 'new',
}

class RealEstatesStore {
    realEstates: RealEstate[] = [];
    realEstateExtras: RealEstateExtra[] = [];

    constructor() {
        makeObservable(this, {
            realEstates: observable,
        });
    }

    setRealEstates(realEstates: RealEstate[]) {
        this.realEstates = realEstates;
    }

    setRealEstateExtras(realEstateExtras: RealEstateExtra[]) {
        this.realEstateExtras = realEstateExtras;
    }

    addRealEstate(realEstate: any) {
        this.realEstates.push(realEstate);
    }

    async uploadRealEstateImages(id: string, form: HTMLFormElement) {
        try {
            await api.request('POST',`/realEstate/${id}/upload`, form, undefined, {headers: {'Content-Type': 'multipart/form-data'}})
        } catch (err) {}
    }

    async removeRealEstateImages(id: string, files: string[]) {
        try {
            await api.request('POST', `/realEstate/${id}/unupload`, {urls: files});
        } catch (err) {}
    }

    async getRealEstate(id: string) {
        const realEstate = this.realEstates.find((realEstate) => realEstate.id === id);
        if (realEstate) {
            return realEstate;
        }

        try {
            const realEstate = await api.request('GET', `/realEstate/${id}`);
            if (realEstate.status === 404) {
                return Promise.reject(realEstate);
            }
            this.addRealEstate(realEstate);
            return realEstate;
        } catch (err) {
            return Promise.reject(err);
        }
    }

    async getRealEstates(ids: string[]) {
        try {
            return Promise.all(ids.map((id) => this.getRealEstate(id)))
        } catch (err) {
            return Promise.reject(err);
        }
    }

    async getRealEstateExtras() {
        if (this.realEstateExtras.length > 0) {
            return this.realEstateExtras;
        }

        try {
            const realEstateExtras = await api.request('GET', 'realEstate/extras');
            this.setRealEstateExtras(realEstateExtras);
            return realEstateExtras;
        } catch (err) {}
    }

    async loadStore(params: {email: string, phone: string, fullName: string, statuses: string[]}) {
        try {
            const {email, phone, fullName, statuses} = params;
            let query = new URLSearchParams({email, phone, fullName}).toString();
            if (statuses) {
                statuses.forEach((status) => query += `&statuses[]=${status}`);
            }
            const realEstates = await api.request('GET', '/realEstates/crm?' + query);
            this.setRealEstates(realEstates);
            return realEstates;
        } catch (err) {}
    }

    async update(id: string, realEstate: RealEstate) {
        try {
            return api.request('PUT', `/realEstate/${id}`, realEstate);
        } catch (err) {}
    }

    async create(realEstate: RealEstateNew) {
        try {
            return api.request('POST', '/realEstate', realEstate);
        } catch (err) {}
    }
}

export type RealEstate = {
    id?: string;
    privacy: boolean;
    client: string;
    municipality: string;
    province: string;
    country: string;
    street: string;
    postcode: string;
    realEstateType: string;
    presentationAddress: string;
    presentationManualAddress: string;
    houseNumber: string;
    ladder: string | null;
    floor: string;
    door: string;
    cadastralNumber: string | null;
    livingArea: number | null;
    plotArea: number | null;
    bathroomCount: number;
    bedroomCount: number;
    buildingYear: number | null;
    condition: string;
    energyCertificate: boolean | null;
    energyClass: string | null;
    extras: number[];
    notes?: string;
    status: string;
    realEstateMarketingName?: string | null;
    realEstateMarketingNameES?: string | null;
    realEstateMarketingDescription?: string | null;
    realEstateMarketingDescriptionES?: string | null;
    previews?: Preview[],
    finalPrice?: number | null;
    price?: number | null;
    longitude?: number | null;
    latitude?: number | null;
    isBestOffer?: boolean;
}

export type RealEstateNew = {
    country: string;
    client: string;
    province?: string | null;
    municipality?: string | null;
    realEstateType?: string;
    price?: number | null;
    email?: string | null;
    notes?: string | null;
    status: string;
}

export type Preview = {
    imagePath: string;
    imageUrl: string;
}

export type RealEstateExtra = {
    id: number;
    name: string;
}

export type Condition = {
    id: number;
    name: string;
    value: string
}

export const conditions: Condition[] = [
    {id: 1, name: 'New', value: 'new'},
    {id: 2, name: 'Good condition', value: 'good condition'},
    {id: 3, name: 'Originally', value: 'originally'},
    {id: 4, name: 'Renovated', value: 'renovated'},
    {id: 5, name: 'To reform', value: 'to reform'},
]

// 🏠 Жилая недвижимость / Residential Real Estate
// 1.	Apartamento / Apartment – самая распространённая форма жилья в городах и на побережье. Может быть студией (Estudio / Studio) или многокомнатной квартирой.
// 2.	Piso / Flat – чаще всего многоквартирные дома с несколькими спальнями, подходят для семей.
// 3.	Ático / Penthouse – квартира на верхнем этаже здания, часто с террасой и панорамным видом.
// 4.	Dúplex / Duplex – двухэтажная квартира внутри многоквартирного дома.
// 5.	Chalet / Cottage, Chalet – отдельный дом, обычно с садом и бассейном, встречается в пригородах и курортных зонах.
// 6.	Villa / Villa – роскошный особняк с большим участком земли, чаще всего на побережье или в элитных районах.
// 7.	Casa adosada / Townhouse – рядный дом, соединённый с соседними, часто имеет несколько этажей и небольшой двор.
// 8.	Casa de pueblo / Village House – традиционный дом в сельской местности, иногда требует реставрации.
// 9.	Finca rústica / Country Estate, Rural Property – загородная усадьба, часто с сельскохозяйственными постройками и землёй.
//
// 🏢 Коммерческая недвижимость / Commercial Real Estate
// 10.	Local comercial / Commercial Space – магазин, кафе, ресторан или другой бизнес-объект.
// 11.	Oficina / Office – помещение для работы, может быть в бизнес-центре или жилом доме.
// 12.	Hotel / Hotel – гостиницы и мини-отели, доступные для инвестиций.
//
// 🏗 Инвестиционная и специальная недвижимость / Investment & Special Real Estate
// 13.	Edificio / Entire Building – многоквартирный дом или офисное здание, продаётся для инвестиций.
// 14.	Terreno / Land Plot – участки под строительство жилья, бизнеса или сельского хозяйства.

export const realEstateResidentialTypes: RealEstateType[] = [
    {id: 1, label: 'Apartment', value: 'apartment'},
    {id: 2, label: 'Flat', value: 'flat'},
    {id: 3, label: 'Penthouse', value: 'penthouse'},
    {id: 4, label: 'Duplex', value: 'duplex'},
    {id: 5, label: 'Cottage/Chalet', value: 'cottage_or_chalet'},
    {id: 6, label: 'Villa', value: 'villa'},
    {id: 7, label: 'Townhouse', value: 'townhouse'},
    {id: 8, label: 'Village House', value: 'village_house'},
    {id: 9, label: 'Country Estate/Rural Property', value: 'country_estate_or_rural_property'},
]

export const realEstateCommercialTypes: RealEstateType[] = [
    {id: 10, label: 'Commercial Space', value: 'commercial_space'},
    {id: 11, label: 'Office', value: 'office'},
    {id: 12, label: 'Entire Building', value: 'entire_building'},
]

export type RealEstateType = {
    id: number;
    label: string;
    value: string;
}

export type Municipality = {
    id?: number;
    name: string;
    state_id: number;
    state_code: string;
    state_name: string;
    country_id: number;
    country_code: string;
    country_name: string;
    latitude: string;
    longitude: string;
    wikiDataId: string | null;
    meta?: {
        revision: number;
        created: number;
        version: number;
    },
    $loki?: number;
}

export type Street = {
    presentationAddress: string;
    street: string;
    postcode: string;
    district: string;
    area: string;
    municipality: string;
}

export const realEstatesStore = new RealEstatesStore();
export const realEstatesStoreContext = createContext(realEstatesStore);
