import React, {useContext, useEffect, useState} from 'react';
import './RealEstateDetails.scss';
import {observer} from 'mobx-react';
import {
    conditions, Municipality,
    Preview,
    RealEstate,
    RealEstateExtra,
    realEstatesStoreContext, RealEstateType,
} from '../../store/components/realEstatesStore';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {
    Alert,
    Badge,
    Button,
    Card, CardBody, CardHeader,
    CardTitle,
    Col,
    Container,
    Form, FormGroup,
    Input,
    InputGroup, InputGroupText, Label,
    Row, Spinner
} from 'reactstrap';
import moment from 'moment/moment';
import {clone, extraIconsMap} from '../../utils/functions';
import Editor, {ContentEditableEvent} from 'react-simple-wysiwyg';
import MunicipalitiesCombobox from '../../components/MunicipalitiesCombobox/MunicipalitiesCombobox';
import {Province, provinceStoreContext} from '../../store/components/provinceStore';
import {usersStoreContext} from '../../store/components/usersStore';
import {Client, clientsStoreContext} from '../../store/components/clientsStore';
import RealEstatesSelect from '../../components/RealEstatesSelect/RealEstatesSelect';
import {SingleValue} from 'react-select';

type FileToUpload = {
    id: string;
    preview: string;
    file: File;
}

type ExtraActive = {
    [key: string]: boolean;
}

type RealEstateState = {
    realEstate: RealEstate,
    realEstateClient: Client,
    extras: RealEstateExtra[],
    extrasActive: ExtraActive,
}

const RealEstateDetails = observer(() => {
    let { id } = useParams();
    const navigate = useNavigate();
    const usersStoreCtx = useContext(usersStoreContext);
    const realEstateStoreCtx = useContext(realEstatesStoreContext);
    const provinceStoreCtx = useContext(provinceStoreContext);
    const clientStoreCtx = useContext(clientsStoreContext);
    const [isLoading, setLoading] = useState(true);

    const [submittingState, setSubmittingState] = useState({isUpdate: 'none', isUpload: 'none', isUnupload: 'none'});
    const [filesToUpload, setFilesToUpload] = useState<FileToUpload[]>([]);
    const [filesToRemove, setFilesToRemove] = useState<string[]>([]);
    const [previews, setPreviews] = useState<Preview[]>([]);
    const [mode, setMode] = useState('edit');
    const numberFields = {
        buildingYear: true,
        plotArea: true,
        livingArea: true,
        finalPrice: true,
        price: true,
        longitude: true,
        latitude: true
    };

    const [realEstateState, setRealEstateState] = useState({
        realEstate: {} as RealEstate,
        realEstateClient:  {} as Client | null,
        extras: [],
        extrasActive: {} as ExtraActive
    });
    const [realEstateDefault, setRealEstateDefault] = useState<RealEstate>({} as RealEstate);
    const [provinces] = useState<Province[]>(provinceStoreCtx.getProvinces());

    useEffect(() => {
        // @ts-ignore
        realEstateStoreCtx.getRealEstate(id).then((result) => {
            realEstateStoreCtx.getRealEstateExtras().then((result2) => {
                if (result.client) {
                    clientStoreCtx.get(result.client).then((result3) => {
                        const realEstateResponse = clone(result);
                        setRealEstateState({
                            realEstate: realEstateResponse,
                            realEstateClient: result3,
                            extras: result2,
                            extrasActive: result2.reduce((acc: ExtraActive, extra: RealEstateExtra) => {
                                acc[extra.id] = !!([].concat(realEstateResponse.extras) || []).find((id: number) => id === extra.id);
                                return acc;
                            }, {} as RealEstateState)
                        })
                        setRealEstateDefault(realEstateResponse);
                        setPreviews(realEstateResponse.previews ? [].concat(realEstateResponse.previews) : []);
                        setMode(realEstateResponse.status === 'published' && !usersStoreCtx.isAdmin() ? 'static' : 'edit');
                        setLoading(false);
                    });
                } else {
                    const realEstateResponse = clone(result);
                    setRealEstateState({
                        realEstate: realEstateResponse,
                        realEstateClient: null,
                        extras: result2,
                        extrasActive: result2.reduce((acc: ExtraActive, extra: RealEstateExtra) => {
                            acc[extra.id] = !!([].concat(realEstateResponse.extras) || []).find((id: number) => id === extra.id);
                            return acc;
                        }, {} as RealEstateState)
                    })
                    setRealEstateDefault(realEstateResponse);
                    setPreviews(realEstateResponse.previews ? [].concat(realEstateResponse.previews) : []);
                    setMode(realEstateResponse.status === 'published' && !usersStoreCtx.isAdmin() ? 'static' : 'edit');
                    setLoading(false);
                }
            });
        }).catch(err => {
            if (err.status === 404) {
                navigate('/');
            }
        });
    }, []);

    const getBase64 = async (file: File) => {
        return new Promise((resolve) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                resolve(reader.result);
            };
            reader.onerror = function (error) {
                console.log('Convert to base64 error: ', error);
            };
        });
    }

    const handleAddFilesToLoad = async (event: React.ChangeEvent<HTMLInputElement>) => {
        // Date.now().toString(36) + Math.random().toString(36).substring(2)
        const uploads: FileToUpload[] = [];
        const files = event.target.files;
        if (!files) {
            return;
        }

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const base64 = await getBase64(file);
            uploads.push({
                id: 'preview_image_' + Date.now().toString(36) + Math.random().toString(36).substring(2),
                preview: base64,
                file: file,
            } as FileToUpload)
        }

        setFilesToUpload(filesToUpload.concat(uploads));
        event.target.value = '';
    }

    const handleFilesUpload = async (position: number) => {
        try {
            const formData = new FormData();
            const file = filesToUpload[position];
            formData.append(file.id, file.file, file.file.name);
            setSubmittingState({isUpdate: 'none', isUpload: 'true', isUnupload: 'none'});
            // @ts-ignore
            await realEstateStoreCtx.uploadRealEstateImages(id, formData);
            if (position < (filesToUpload.length - 1)) {
                await handleFilesUpload(position + 1);
            } else if (!filesToRemove || !filesToRemove.length) {
                setSubmittingState({isUpdate: 'none', isUpload: 'none', isUnupload: 'none'});
                window.location.reload();
            }
        } catch (err) {
            console.log('[RealEstateDetails.tsx][handleFilesUpload] ERROR: ', err);
        }
    }

    const handleFilesRemove = async () => {
        setSubmittingState({isUpdate: 'none', isUpload: 'none', isUnupload: 'true'});
        // @ts-ignore
        await realEstateStoreCtx.removeRealEstateImages(id, filesToRemove);
        setSubmittingState({isUpdate: 'none', isUpload: 'none', isUnupload: 'none'});
        window.location.reload();
    }

    const handleRemoveUploaded = (file: Preview) => {
        const files = previews.filter((f: Preview) => f.imagePath !== file.imagePath);
        setPreviews(files);
        setFilesToRemove(filesToRemove.concat([file.imagePath]));
    }
    const handleRemoveFileToUpload = (file: FileToUpload) => {
        const files = filesToUpload.filter((f) => f.id !== file.id);
        setFilesToUpload(files);
    }

    const merge = (data: any) => {
        const realEstate = {...realEstateState.realEstate, ...data};
        setRealEstateState({...realEstateState, ...{realEstate}});
    }

    const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        merge({[field]: field === 'energyCertificate' ? Boolean(+event.target.value) : event.target.value});
    }

    const handleChangeRealEstateType = (item: SingleValue<RealEstateType>) => merge({realEstateType: item ? item.value : null});

    const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement> | ContentEditableEvent, field: string) => {
        let value = event.target.value;
        // @ts-ignore
        if (field && numberFields[field]) {
            let regexp = '[^0-9]';
            if (['longitude', 'latitude'].includes(field)) {
                regexp = '[^0-9.-]';
            }
            if (['plotArea', 'livingArea', 'finalPrice', 'price'].includes(field)) {
                regexp = '[^0-9.]';
            }
            let reg = new RegExp(regexp, 'g');
            value = value.replace(reg, '');
        }

        merge({[field]: value});
    }

    const handleChangeExtraState = (extra: RealEstateExtra) => {
        if (mode === 'static') {
            return;
        }
        const extrasActive = {...realEstateState.extrasActive, ...{[extra.id]: !realEstateState.extrasActive[extra.id]}};
        setRealEstateState({...realEstateState, ...{extrasActive}});
    }

    const getExtrasActive = () => {
        return Object.keys(extrasActive).reduce((acc, id) => {
            if (extrasActive[id]) {
                acc.push(+id);
            }
            return acc;
        }, [] as number[]);
    }

    const handleSelectMunicipality = (municipality: Municipality | null) => {
        merge({municipality: municipality ? municipality.name : ''});
    }

    const hasSubmitDisabled = () => {
        const defaultStateJSON = JSON.stringify(realEstateDefault);
        const currentState = clone(realEstateState.realEstate);
        currentState.extras = getExtrasActive();
        const currentStateJSON = JSON.stringify(currentState);
        return defaultStateJSON === currentStateJSON && !filesToRemove.length && !filesToUpload.length;
    }

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        event.stopPropagation();
        const state = clone(realEstateState.realEstate);
        for (let key in state) {
            // @ts-ignore
            if (numberFields[key]) {
                const val = parseFloat(state[key]);
                state[key] = val || null;
            }
        }

        if ((state.status === 'published')
            && (!state.municipality
            || !state.presentationManualAddress
            || !state.price
            || !state.longitude
            || !state.latitude
            || !realEstate.realEstateMarketingName
            || !realEstate.realEstateMarketingDescription
            || !realEstate.realEstateMarketingNameES
            || !realEstate.realEstateMarketingDescriptionES
        )) {
            alert('Fields: Municipality, Street for catalog, Longitude, Latitude, Marketing address, Marketing address description are required before saving');
            return;
        }

        document.querySelectorAll('.validation-error').forEach((elem) => {
            elem.classList.remove('validation-error');
            elem.removeAttribute('data-error');
        });

        state.extras = getExtrasActive();
        if (state.status === 'sales') {
            let finalPrice = prompt('Please enter the transaction amount', state.price);
            state.finalPrice = finalPrice ? parseFloat(finalPrice) : null;

            if (!state.finalPrice) {
                state.status = realEstateDefault.status;
            }
        }

        if (realEstateDefault.status !== 'published' && state.status === 'published') {
            if (!window.confirm("Attention, transferring an object to Published status will make it available on the site of proposals. Do you really want to do this?")) {
                state.status = realEstateDefault.status;
            }
        }
        setSubmittingState({isUpdate: 'true', isUpload: 'none', isUnupload: 'none'});
        try {
            await realEstateStoreCtx.update(state.id, state);
            if (!filesToUpload.length && !filesToRemove.length) {
                window.location.reload();
            }
            if (filesToUpload.length > 0) {
                await handleFilesUpload(0);
            }
            if (filesToRemove.length > 0) {
                await handleFilesRemove();
            }
        } catch (err: any) {
            setSubmittingState({isUpdate: 'none', isUpload: 'none', isUnupload: 'none'});
            if (err && err.data && err.data.errors) {
                console.log(err.data.errors);
                err.data.errors.forEach((err: {type: string; message: string;}) => {
                    // @ts-ignore
                    const field = /"(.+)"/.exec(err.message)[1];
                    const message = err.message.replace(/"(.+)" /, '');
                    const fieldElement = document.querySelector(`[name="${field}"]`);
                    if (fieldElement) {
                        // @ts-ignore
                        fieldElement.parentElement.classList.add('validation-error');
                        // @ts-ignore
                        fieldElement.parentElement.setAttribute('data-error', message);
                    }
                });
            }
        }
    }

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!realEstateState.realEstate) {
        return <div>Real estate with id: {id} is empty</div>
    }

    const realEstate = realEstateState.realEstate;
    const realEstateClient = realEstateState.realEstateClient;
    const extras = realEstateState.extras;
    const extrasActive = realEstateState.extrasActive;
    const municipality = realEstate.municipality;
    const isDisabled = mode === 'static';
    const isDisabledSubmit = hasSubmitDisabled();
    const isAdmin = usersStoreCtx.isAdmin();

    return (<Container className={'re'}>
        {(submittingState.isUpdate !== 'none' || submittingState.isUpload !== 'none' || submittingState.isUnupload !== 'none') && <>
            <div className="save-overlay"></div>
            <div className="save-progress">
                <h3>We are saving real estate</h3>
                <div>
                    {
                        submittingState.isUpdate !== 'none'
                        && <div className="save-progress__item">
                            <Spinner color="light" size={'sm'}/>
                            <span>Update real estate</span>
                        </div>
                    }
                    {
                        submittingState.isUpload !== 'none'
                        && <div className="save-progress__item">
                            <Spinner color="light" size={'sm'}/>
                            <span>Upload preview images</span>
                        </div>
                    }
                    {
                        submittingState.isUnupload !== 'none'
                        && <div className="save-progress__item">
                            <Spinner color="light" size={'sm'}/>
                            <span>Remove preview images</span>
                        </div>
                    }
                </div>
            </div>
        </>}
        <Form onSubmit={handleSubmit}>
            <Card>
                <CardHeader className="card-header-details">
                    <Row>
                        <Col sm="12" md="4" lg="6">
                            ID: {realEstate.id}
                        </Col>
                        <Col sm="8" md="5" lg="5">
                            <Row className="g-2 align-items-center justify-content-between">
                                <Col sm="6" className="ta-right">Status:</Col>
                                <Col sm="6">
                                    <Input
                                        disabled={isDisabled}
                                        bsSize="sm"
                                        id="status"
                                        name="status"
                                        type="select"
                                        defaultValue={realEstate.status}
                                        onChange={(event) => handleChangeSelect(event, 'status')}
                                    >
                                        <option value={'new'}>New</option>
                                        <option value={'paused'}>Paused</option>
                                        {isAdmin ? <option value={'published'}>Published</option> : <option value={'review'}>On review</option>}
                                        {isAdmin && realEstate.status === 'review' && <option value={'review'}>On review</option>}
                                        {!isAdmin && realEstate.status === 'published' && <option value={'published'} disabled={true}>Published</option>}
                                        <option value={'closed'}>Closed</option>
                                        <option value={'sales'}>Sales</option>
                                    </Input>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="8" md="3" lg="1">
                            <Button size="sm" type="submit" color={isDisabledSubmit ? "" : "warning"} disabled={isDisabledSubmit}>
                                Update
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>

                {
                    realEstateClient &&
                    <Row className="details-card">
                        <Col sm="12">
                            <Card body>
                                <CardTitle tag="h4">
                                    Client information
                                </CardTitle>
                                <Row>
                                    <Col sm="3">
                                        <label htmlFor="fullName" className="form-label">Name</label>
                                        <div><Link to={`/client/${realEstateClient.id}`}>{realEstateClient.fullName ?? '-'}</Link></div>
                                    </Col>
                                    <Col sm="3">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <div>{realEstateClient.email ?? '-'}</div>
                                    </Col>
                                    <Col sm="3">
                                        <label htmlFor="phone" className="form-label">Phone</label>
                                        <div>{realEstateClient.phone ? realEstateClient.phoneCode + realEstateClient.phone : '-'}</div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                }
            <Row className="details-card">
                <Col sm="12">
                    <Card body>
                        <CardTitle tag="h4">
                            Real estate notes
                        </CardTitle>
                        <Row className="mt-8">
                            <Col sm="12">
                                <Input
                                    id="notes"
                                    name="notes"
                                    type="textarea"
                                    value={realEstate.notes || ''}
                                    onChange={(event) => handleChangeTextField(event, 'notes')}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row className="details-card">
                <Col sm="12">
                    <Card body>
                        <CardTitle tag="h4">
                            Real estate information
                        </CardTitle>
                        <Row>
                            <Col sm="2">
                                <label htmlFor="country" className="form-label">Country</label>
                                <Input
                                    bsSize="sm"
                                    disabled
                                    id="country"
                                    name="country"
                                    type="text"
                                    value={realEstate.country || ''}
                                />
                            </Col>
                            <Col sm="2">
                                <label htmlFor="province" className="form-label">Province *</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="province"
                                    name="province"
                                    type="select"
                                    defaultValue={realEstate.province}
                                    onChange={(event) => handleChangeSelect(event, 'province')}
                                >
                                    {provinces.map((province) => {
                                        return (<option value={province.name} key={'province_' + province.name}>
                                            {province.name}
                                        </option>)
                                    })}
                                </Input>
                            </Col>
                            <Col sm="2">
                                <label htmlFor="municipality" className="form-label">Municipality *</label>
                                {
                                    isAdmin ?
                                        <MunicipalitiesCombobox
                                            initBy={municipality ? municipality : null}
                                            province={provinceStoreCtx.getProvince(realEstate.province)}
                                            onSelect={handleSelectMunicipality}
                                            icon={'icon-location'}
                                        />
                                        :
                                        <Input
                                            bsSize="sm"
                                            disabled
                                            id="municipality"
                                            name="municipality"
                                            type="text"
                                            value={realEstate.municipality || ''}
                                        />
                                }
                            </Col>
                            <Col sm="5">
                                <label htmlFor="presentationAddress" className="form-label">Street *</label>
                                {
                                    isAdmin ?
                                        <Input
                                            bsSize="sm"
                                            id="presentationAddress"
                                            name="presentationAddress"
                                            type="text"
                                            value={realEstate.presentationAddress || ''}
                                            onChange={(event) => handleChangeTextField(event, 'presentationAddress')}
                                        />
                                        :
                                        <Input
                                            bsSize="sm"
                                            disabled
                                            id="presentationAddress"
                                            name="presentationAddress"
                                            type="text"
                                            value={realEstate.presentationAddress || ''}
                                        />
                                }
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col sm="12" md="2" lg="2">
                                <label htmlFor="realEstateType" className="form-label">Type</label>
                                <RealEstatesSelect type={realEstate.realEstateType || null} onChange={handleChangeRealEstateType}/>
                            </Col>
                            <Col sm="12" md="2" lg="2">
                                <label htmlFor="condition" className="form-label">Condition</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="condition"
                                    name="condition"
                                    type="select"
                                    defaultValue={realEstate.condition}
                                    onChange={(event) => handleChangeSelect(event, 'condition')}
                                >
                                    {conditions.map((condition) => {
                                        return (<option value={condition.value} key={'condition_' + condition.id}>
                                            {condition.name}
                                        </option>)
                                    })}
                                </Input>
                            </Col>
                            <Col sm="2">
                                <label htmlFor="buildingYear" className="form-label">Building year</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="buildingYear"
                                    name="buildingYear"
                                    type="text"
                                    value={realEstate.buildingYear ? +realEstate.buildingYear : ''}
                                    onChange={(event) => handleChangeTextField(event, 'buildingYear')}
                                />
                            </Col>
                            <Col sm="2">
                                <label htmlFor="energyCetrificate" className="form-label">Energy certificate</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="energyCetrificate"
                                    name="energyCetrificate"
                                    type="select"
                                    defaultValue={typeof realEstate.energyCertificate === 'boolean' ? String(+realEstate.energyCertificate) : '-1'}
                                    onChange={(event) => handleChangeSelect(event, 'energyCertificate')}
                                >
                                    <option disabled value="-1"></option>
                                    {energies.map((energy) => {
                                        return (<option value={energy.value ? '1' : '0'} key={'energy_' + energy.id}>
                                            {energy.name}
                                        </option>)
                                    })}
                                </Input>
                            </Col>
                            {
                                realEstate.energyCertificate === true && realEstate.energyClass &&
                                <Col sm="2">
                                    <label htmlFor="energyCetrificate" className="form-label">Certificate class</label>
                                    <Input
                                        bsSize="sm"
                                        disabled={isDisabled}
                                        id="energyCetrificate"
                                        name="energyCetrificate"
                                        type="select"
                                        defaultValue={realEstate.energyClass ? realEstate.energyClass : '-1'}
                                        onChange={(event) => handleChangeSelect(event, 'energyClass')}
                                    >
                                        <option disabled value="-1"></option>
                                        {energyClasses.map((energyClass) => {
                                            return (<option value={energyClass.value} key={'energyClass_' + energyClass.id}>
                                                {energyClass.name}
                                            </option>)
                                        })}
                                    </Input>
                                </Col>
                            }
                        </Row>
                        <Row className="mt-8">
                            <Col sm="2">
                                <label htmlFor="house" className="form-label">House</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="house"
                                    name="house"
                                    type="text"
                                    value={realEstate.houseNumber ? +realEstate.houseNumber : ''}
                                    onChange={(event) => handleChangeTextField(event, 'houseNumber')}
                                />
                            </Col>
                            <Col sm="2">
                                <label htmlFor="floor" className="form-label">Floor</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="floor"
                                    name="floor"
                                    type="text"
                                    value={realEstate.floor || ''}
                                    onChange={(event) => handleChangeTextField(event, 'floor')}
                                />
                            </Col>
                            <Col sm="2">
                                <label htmlFor="ladder" className="form-label">Ladder</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="ladder"
                                    name="ladder"
                                    type="text"
                                    value={realEstate.ladder || ''}
                                    onChange={(event) => handleChangeTextField(event, 'ladder')}
                                />
                            </Col>
                            <Col sm="2">
                                <label htmlFor="door" className="form-label">Door</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="door"
                                    name="door"
                                    type="text"
                                    value={realEstate.door || ''}
                                    onChange={(event) => handleChangeTextField(event, 'door')}
                                />
                            </Col>
                            <Col sm="2">
                                <label htmlFor="postcode" className="form-label">Postcode</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="postcode"
                                    name="postcode"
                                    type="text"
                                    value={realEstate.postcode ? +realEstate.postcode : ''}
                                    onChange={(event) => handleChangeTextField(event, 'postcode')}
                                />
                            </Col>
                            <Col sm="2">
                                <label htmlFor="cadastralNumber" className="form-label">Cadastral number</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="cadastralNumber"
                                    name="cadastralNumber"
                                    type="text"
                                    value={realEstate.cadastralNumber || ''}
                                    onChange={(event) => handleChangeTextField(event, 'cadastralNumber')}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-8">
                            <Col sm="2">
                                <label htmlFor="livingArea" className="form-label">Living area</label>
                                <InputGroup size="sm">
                                    <Input
                                        bsSize="sm"
                                        disabled={isDisabled}
                                        id="livingArea"
                                        name="livingArea"
                                        type="text"
                                        value={realEstate.livingArea ? String(realEstate.livingArea) : ''}
                                        onChange={(event) => handleChangeTextField(event, 'livingArea')}
                                    />
                                    <InputGroupText>
                                        m2
                                    </InputGroupText>
                                </InputGroup>
                            </Col>
                            <Col sm="2">
                                <label htmlFor="plotArea" className="form-label">Plot area</label>
                                <InputGroup size="sm">
                                    <Input
                                        bsSize="sm"
                                        disabled={isDisabled}
                                        id="plotArea"
                                        name="plotArea"
                                        type="text"
                                        value={realEstate.plotArea ? String(realEstate.plotArea) : ''}
                                        onChange={(event) => handleChangeTextField(event, 'plotArea')}
                                    />
                                    <InputGroupText>
                                        m2
                                    </InputGroupText>
                                </InputGroup>
                            </Col>
                            <Col sm="2">
                                <label htmlFor="bedroomCount" className="form-label">Bedrooms</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="bedroomCount"
                                    name="bedroomCount"
                                    type="text"
                                    maxLength={2}
                                    value={realEstate.bedroomCount ? realEstate.bedroomCount : ''}
                                    onChange={(event) => handleChangeTextField(event, 'bedroomCount')}
                                />
                            </Col>
                            <Col sm="2">
                                <label htmlFor="bathroomCount" className="form-label">Bathrooms</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="bathroomCount"
                                    name="bathroomCount"
                                    type="text"
                                    maxLength={2}
                                    value={realEstate.bathroomCount ? realEstate.bathroomCount : ''}
                                    onChange={(event) => handleChangeTextField(event, 'bathroomCount')}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-24">
                            <Col sm="12">
                                <Row>
                                    <Col sm="12">
                                        Extras
                                    </Col>
                                </Row>
                                <div className={`user-extras ${isDisabled ? 'user-extras-disabled' : ''}`}>
                                    {extras.map((extra: RealEstateExtra) => {
                                        const isActive = extrasActive[extra.id];

                                        if (isActive) {
                                            return <Badge color={'success'} key={'extra_' + extra.id} onClick={() => handleChangeExtraState(extra)}>
                                                {/*@ts-ignore*/}
                                                <i className={`${extraIconsMap[extra.id]}`}/>
                                                {extra.name}
                                            </Badge>
                                        }

                                        return (<Badge color={'light'} className="text-dark" key={'extra_' + extra.id} onClick={() => handleChangeExtraState(extra)}>
                                            {/*@ts-ignore*/}
                                            <i className={`${extraIconsMap[extra.id]}`}/>
                                            {extra.name}
                                        </Badge>);
                                    })}
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-24">
                            <Col sm="4">
                                <label htmlFor="presentationManualAddress" className="form-label">Street for catalog *</label>
                                <Input
                                    bsSize="sm"
                                    id="presentationManualAddress"
                                    name="presentationManualAddress"
                                    type="text"
                                    value={realEstate.presentationManualAddress || ''}
                                    onChange={(event) => handleChangeTextField(event, 'presentationManualAddress')}
                                />
                            </Col>
                            <Col sm="2">
                                <label htmlFor="price" className="form-label">Price *</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="price"
                                    name="price"
                                    type="text"
                                    value={realEstate.price ? +realEstate.price : ''}
                                    onChange={(event) => handleChangeTextField(event, 'price')}
                                />
                            </Col>
                            {
                                realEstate.status === 'closed' && typeof realEstate.finalPrice === 'number' && realEstate.finalPrice > 0 && <Col sm="2">
                                    <label htmlFor="finalPrice" className="form-label">Final price</label>
                                    <Input
                                        bsSize="sm"
                                        disabled={isDisabled}
                                        id="finalPrice"
                                        name="finalPrice"
                                        type="text"
                                        value={realEstate.finalPrice ? +realEstate.finalPrice : ''}
                                        onChange={(event) => handleChangeTextField(event, 'finalPrice')}
                                    />
                                </Col>
                            }
                            {
                                isAdmin && <Col sm="3" className={'best-offer'}>
                                    <FormGroup switch>
                                        <Input
                                            disabled={isDisabled}
                                            id="best-offer"
                                            type="switch"
                                            checked={realEstate.isBestOffer}
                                            onClick={() => merge({isBestOffer: !realEstate.isBestOffer})}
                                        />
                                        <Label htmlFor="best-offer">Mark is best offer</Label>
                                    </FormGroup>
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col sm="2">
                                <label htmlFor="latitude" className="form-label">Latitude *</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="latitude"
                                    name="latitude"
                                    type="text"
                                    value={realEstate.latitude ? String(realEstate.latitude) : ''}
                                    onChange={(event) => handleChangeTextField(event, 'latitude')}
                                />
                            </Col>
                            <Col sm="2">
                                <label htmlFor="longitude" className="form-label">Longitude *</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="longitude"
                                    name="longitude"
                                    type="text"
                                    value={realEstate.longitude ? String(realEstate.longitude) : ''}
                                    onChange={(event) => handleChangeTextField(event, 'longitude')}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-16">
                            <Col sm="12">
                                <label htmlFor="realEstateMarketingName" className="form-label">Marketing address *(a beautiful name for an object in the catalog)</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="realEstateMarketingName"
                                    name="realEstateMarketingName"
                                    type="text"
                                    value={realEstate.realEstateMarketingName || ''}
                                    onChange={(event) => handleChangeTextField(event, 'realEstateMarketingName')}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-8">
                            <Col sm="12">
                                <label htmlFor="realEstateMarketingDescription" className="form-label">Marketing description *(a beautiful text for an object in the catalog)</label>
                                <Editor
                                    disabled={isDisabled}
                                    id="realEstateMarketingDescription"
                                    name="realEstateMarketingDescription"
                                    value={realEstate.realEstateMarketingDescription || ''}
                                    onChange={(event) => handleChangeTextField(event, 'realEstateMarketingDescription')} />
                            </Col>
                        </Row>
                        <Row className="mt-16">
                            <Col sm="12">
                                <label htmlFor="realEstateMarketingNameES" className="form-label">Marketing address *(ES)(a beautiful name for an object in the catalog)</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="realEstateMarketingNameES"
                                    name="realEstateMarketingNameES"
                                    type="text"
                                    value={realEstate.realEstateMarketingNameES || ''}
                                    onChange={(event) => handleChangeTextField(event, 'realEstateMarketingNameES')}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-8">
                            <Col sm="12">
                                <label htmlFor="realEstateMarketingDescriptionES" className="form-label">Marketing description *(ES)(a beautiful text for an object in the catalog)</label>
                                <Editor
                                    disabled={isDisabled}
                                    id="realEstateMarketingDescriptionES"
                                    name="realEstateMarketingDescriptionES"
                                    value={realEstate.realEstateMarketingDescriptionES || ''}
                                    onChange={(event) => handleChangeTextField(event, 'realEstateMarketingDescriptionES')} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row className="details-card">
                <Col sm="12">
                    <Card body>
                        <CardTitle tag="h4">
                            Real estate images
                        </CardTitle>
                        <div className="previews">
                            {previews && previews.length === 0 && filesToUpload.length === 0 && <Alert color="warning">
                                No images for display
                            </Alert>}
                            {previews && previews.length > 0 &&  previews.map((f) => <div className="preview preview-uploaded" key={f.imageUrl}>
                                <img src={f.imageUrl} alt='preview'/>
                                {!isDisabled && <i className="icon-close" onClick={() => handleRemoveUploaded(f)}/>}
                            </div>)}
                            {filesToUpload.length > 0 && filesToUpload.map((f) => <div className="preview preview-queue-upload" key={f.id}>
                                <img src={f.preview} alt='preview'/>
                                {!isDisabled && <i className="icon-close" onClick={() => handleRemoveFileToUpload(f)}/>}
                            </div>)}
                        </div>
                        <input disabled={isDisabled} type="file" id="files" multiple onChange={handleAddFilesToLoad} accept="image/png, image/jpeg"/>
                        {
                            !isDisabled && <label className="btn btn-secondary" htmlFor="files">
                                Choose files
                            </label>
                        }
                    </Card>
                </Col>
            </Row>
                </CardBody>
            </Card>
        </Form>
    </Container>);
})

export type Energy = {
    id: number;
    name: string;
    value: boolean
}

export type EnergyClass = {
    id: number;
    name: string;
    value: string
}

const energyClasses: EnergyClass[] = [
    {id: 1, name: 'A', value: 'A'},
    {id: 2, name: 'B', value: 'B'},
    {id: 2, name: 'C', value: 'C'},
    {id: 2, name: 'D', value: 'D'},
    {id: 2, name: 'E', value: 'E'},
    {id: 2, name: 'F', value: 'F'},
    {id: 2, name: 'G', value: 'G'},
]

const energies: Energy[] = [
    {id: 1, name: 'Yes', value: true},
    {id: 2, name: 'No', value: false},
]

export default RealEstateDetails;
