import React, {useContext, useEffect, useState} from 'react';
import './ClientDetails.scss';
import {observer} from 'mobx-react';
import {useNavigate, useOutletContext, useParams} from 'react-router-dom';
import {
    Client,
    clientsStoreContext,
    clientStatuses,
    ClientStatusOptions, ClientType
} from '../../store/components/clientsStore';
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container,
    Form,
    Input,
    InputGroup,
    Row, Table
} from 'reactstrap';
import {clone, currencyFormatter} from '../../utils/functions';
import {ContentEditableEvent} from 'react-simple-wysiwyg';
import FieldPhone from '../../components/FieldPhone/FieldPhone';
import {Province, provinceStoreContext} from '../../store/components/provinceStore';
import Select, {SingleValue} from 'react-select';
import {RealEstate, realEstatesStoreContext, RealEstateType} from '../../store/components/realEstatesStore';
import moment from 'moment/moment';
import RealEstateStatus from '../../components/RealEstateStatus/RealEstateStatus';
import ClientTypesSelect from '../../components/ClientTypesSelect/ClientTypesSelect';
import ProvincesSelect from '../../components/ProvincesSelect/ProvincesSelect';
import RealEstatesSelect from '../../components/RealEstatesSelect/RealEstatesSelect';

const ClientDetails = observer(() => {
    let { id } = useParams();
    const {setRealEstateModalProps} = useOutletContext<any>();
    const clientsStoreCtx = useContext(clientsStoreContext);
    const provinceStoreCtx = useContext(provinceStoreContext);
    const realEstateStoreCtx = useContext(realEstatesStoreContext);
    const [clientState, setClientState] = useState({
        clientState: {} as Client,
        realEstates: [] as RealEstate[],
    });
    const [clientDefault, setClientDefault] = useState({} as Client);
    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [provinces] = useState<Province[]>(provinceStoreCtx.getProvinces());
    const navigate = useNavigate();
    useEffect(() => {
        if (!id) {
            return;
        }

        const setClientData = (client: Client, realEstates?: RealEstate[]) => {
            setClientState({clientState: client, realEstates: realEstates ?? []});
            setClientDefault(client);
            setLoading(false);
        }
        clientsStoreCtx.get(id).then((client) => {
            if (client.realEstates && client.realEstates.length > 0) {
                realEstateStoreCtx.getRealEstates(client.realEstates).then((realEstates) => {
                    setClientData(client, realEstates)
                });
            } else {
                setClientData(client);
            }
        }).catch(() => {});
    }, [clientsStoreCtx, realEstateStoreCtx, id]);

    const handleNavigateToRealEstateDetails = (id: string) => {
        navigate(`/estate/${id}`);
    }

    const hasSubmitDisabled = () => {
        const defaultStateJSON = JSON.stringify(clientDefault);
        const currentState = clone(clientState.clientState);
        const currentStateJSON = JSON.stringify(currentState);
        return defaultStateJSON === currentStateJSON;
    }

    const merge = (data: any) => {
        const state = {...clientState.clientState, ...data};
        setClientState({...clientState, ...{clientState: state}});
    }

    const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>, field: string) =>
        merge({[field]: event.target.value});
    const handleChangeClientType = (item: SingleValue<ClientType>) =>
        merge({type: item ? item.value : null});

    const handleChangeRealEstateType = (item: SingleValue<RealEstateType>) =>
        merge({realEstateType: item ? item.value : null});
    const handleChangeProvince = (item: SingleValue<Province>) =>
        merge({province: item ? item.name : null});

    const handleChangeStatus = (item: ClientStatusOptions) => merge({'status': item.value});

    const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement> | ContentEditableEvent, field: string) =>
        merge({[field]: event.target.value});

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setSubmitting(true);
        const state = clone(clientState.clientState);
        await clientsStoreCtx.update(state.id, state);
        window.location.reload();
    }

    const handleOpenCreateRealEstateModal = () => setRealEstateModalProps({isOpen: true, client});

    if (isSubmitting) {
        return <div>Save buy details...</div>
    }

    if (isLoading) {
        return (
            <div>Loading...</div>
        )
    }

    const client = clientState.clientState;
    const realEstates = clientState.realEstates;
    const isDisabledSubmit = hasSubmitDisabled();
    const isDisabledEdit = ['closed', 'completed', 'declined'].includes(client.status);

    return (
        <Container className={'client-details'}>
            <Form onSubmit={handleSubmit}>
                <Card>
                    <CardHeader className="card-header-details">
                        <Row>
                            <Col sm="12" md="4" lg="6">
                                ID: {client.id}
                            </Col>
                            <Col sm="8" md="5" lg="5">
                                <Row className="g-2 align-items-center justify-content-between">
                                    <Col sm="6" className="ta-right">Status:</Col>
                                    <Col sm="6">
                                        <Select
                                            styles={{
                                                // @ts-ignore
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    minHeight: '31px',
                                                    height: '31px',
                                                    // @ts-ignore
                                                    background: state.selectProps.value ? state.selectProps.value.background : '#ffffff',
                                                }),
                                                valueContainer: (provided, state) => ({
                                                    ...provided,
                                                    height: '31px',
                                                    padding: '0 6px',
                                                    // @ts-ignore
                                                    color: state.selectProps.value ? state.selectProps.value.color : '#ffffff'
                                                }),
                                                multiValue: (provided) => ({
                                                    ...provided,
                                                    marginTop: '0'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    margin: '0px',
                                                }),
                                                indicatorSeparator: state => ({
                                                    display: 'none',
                                                }),
                                                indicatorsContainer: (provided, state) => ({
                                                    ...provided,
                                                    height: '31px',
                                                }),
                                                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                                                    ...styles,
                                                    background: data.background,
                                                    color: data.color
                                                }),
                                                singleValue: (provided,state) => ({
                                                    ...provided,
                                                    // @ts-ignore
                                                    color: state.selectProps.value ? state.selectProps.value.color : '#000000'
                                                }),
                                            }}
                                            isClearable={false}
                                            options={clientStatuses.filter((status) => status.value !== 'all')}
                                            id="status"
                                            // @ts-ignore
                                            onChange={handleChangeStatus}
                                            value={clientStatuses.find((clientStatus) => clientStatus.value === client.status)}
                                            placeholder={''}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm="8" md="3" lg="1">
                                <Button size="sm" type="submit" color={isDisabledSubmit ? "" : "warning"} disabled={isDisabledSubmit}>
                                    Update
                                </Button>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row className="details-card">
                            <Col sm="12">
                                <Card body>
                                    <CardTitle tag="h4">
                                        Client information
                                    </CardTitle>
                                    <Row>
                                        <Col sm="4">
                                            <label htmlFor="fullName" className="form-label">Name</label>
                                            <Input
                                                disabled={isDisabledEdit}
                                                bsSize="sm"
                                                id="fullName"
                                                name="fullName"
                                                type="text"
                                                value={client.fullName ?? ''}
                                                onChange={(event) => handleChangeTextField(event, 'fullName')}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <label htmlFor="phone" className="form-label">Phone</label>
                                            <InputGroup size="sm">
                                                <FieldPhone
                                                    disabled={isDisabledEdit}
                                                    id="phone"
                                                    type="tel"
                                                    placeholder={'_________'}
                                                    value={client.phone ?? ''}
                                                    onChange={(event) => handleChangeTextField(event, 'phone')}
                                                    name={'phone'}
                                                    maxLength={9}
                                                    phoneCode={client.phone ?? ''}
                                                    onChangePhoneCode={(phoneCode) => merge({phoneCode: phoneCode})}
                                                />
                                            </InputGroup>
                                        </Col>
                                        <Col sm="4">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <Input
                                                disabled={isDisabledEdit}
                                                bsSize="sm"
                                                id="email"
                                                name="email"
                                                type="text"
                                                value={client.email || ''}
                                                onChange={(event) => handleChangeTextField(event, 'email')}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <label htmlFor="type" className="form-label">Type *</label>
                                            <ClientTypesSelect type={client.type || null} onChange={handleChangeClientType}/>
                                        </Col>
                                        <Col sm="4">
                                            <label htmlFor="nie" className="form-label">NIE</label>
                                            <Input
                                                disabled={isDisabledEdit}
                                                bsSize="sm"
                                                id="nie"
                                                name="nie"
                                                type="text"
                                                value={client.nie || ''}
                                                onChange={(event) => handleChangeTextField(event, 'nie')}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <label htmlFor="passport" className="form-label">Passport</label>
                                            <Input
                                                disabled={isDisabledEdit}
                                                bsSize="sm"
                                                id="passport"
                                                name="passport"
                                                type="text"
                                                value={client.passport || ''}
                                                onChange={(event) => handleChangeTextField(event, 'passport')}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <label htmlFor="province" className="form-label">Province</label>
                                            <ProvincesSelect isClearable={true} province={client.province || null} provinces={provinces} onChange={handleChangeProvince}/>
                                        </Col>
                                        <Col sm="4">
                                            <label htmlFor="realEstateType" className="form-label">Real estate type</label>
                                            <RealEstatesSelect isClearable={true} type={client.realEstateType || null} onChange={handleChangeRealEstateType}/>
                                        </Col>
                                        <Col sm="12" md="4" lg="4">
                                            <label htmlFor="price" className="form-label">Price</label>
                                            <Input
                                                disabled={isDisabledEdit}
                                                bsSize="sm"
                                                id="price"
                                                name="price"
                                                type="text"
                                                value={client.price || ''}
                                                onChange={(event) => handleChangeSelect(event, 'price')}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="row-card-description">
                                        <Col sm="12">
                                            <label htmlFor="notes" className="form-label">Notes</label>
                                            <Input
                                                disabled={isDisabledEdit}
                                                id="notes"
                                                name="notes"
                                                type="textarea"
                                                value={client.notes || ''}
                                                onChange={(event) => handleChangeTextField(event, 'notes')}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="details-card">
                            <Col sm="12">
                                <Card body>
                                    <CardTitle tag="h4">
                                        <span>Real estates</span>
                                        <Button disabled={isDisabledEdit} type="button" style={{marginLeft: 'auto'}} onClick={handleOpenCreateRealEstateModal}>Create</Button>
                                    </CardTitle>
                                    <Row>
                                        {
                                            !realEstates.length
                                            && <div>
                                                <Alert color="warning">
                                                    No real estates for display
                                                </Alert>
                                            </div>
                                        }
                                        {
                                            realEstates?.length > 0 &&
                                            <Table bordered hover responsive size={'sm'}>
                                                <thead>
                                                <tr>
                                                    <th style={{minWidth: '90px'}}>ID</th>
                                                    <th>Street/Street for catalog</th>
                                                    <th>Province</th>
                                                    <th>Price</th>
                                                    <th>Preview</th>
                                                    <th>Created</th>
                                                    <th>Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    realEstates.map((realEstate: any) => (
                                                        <tr key={realEstate.id} onClick={() => handleNavigateToRealEstateDetails(realEstate.id)}>
                                                            <td>{realEstate.id}</td>
                                                            <td>
                                                                <span style={{fontWeight: 500}}>{realEstate.presentationAddress}</span><br/>
                                                                {realEstate.presentationManualAddress}
                                                            </td>
                                                            <td>
                                                                {realEstate.province}
                                                            </td>
                                                            <td>
                                                                {realEstate.price ? currencyFormatter(realEstate.price) : 'No price'}
                                                            </td>
                                                            <td>
                                                                {realEstate.previews && realEstate.previews.length ? <div className="real-estate-table-preview-wrapper"><img className="real-estate-table-preview" src={realEstate.previews[0].imageUrl} alt=""/></div> : 'No preview'}
                                                            </td>
                                                            <td>{realEstate.createdAt ? moment(realEstate.createdAt).format('DD MMM YYYY, hh:mm') : ''}</td>
                                                            <td><RealEstateStatus status={realEstate.status}/></td>
                                                        </tr>
                                                    ))
                                                }
                                                </tbody>
                                            </Table>
                                        }
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Form>
        </Container>
    );
})

export default ClientDetails;
