import React, {useContext, useState} from 'react';
import {
    Badge,
    Collapse, Dropdown,
    DropdownItem,
    DropdownMenu, DropdownToggle,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem
} from 'reactstrap';
import './Header.scss';
import {auth} from '../../../services/Firebase';
import {NavLink} from 'react-router-dom';
import {usersStoreContext} from '../../../store/components/usersStore';

const Header: React.FC<{isAuthorized: boolean;}> = (props) => {
    const usersStoreCtx = useContext(usersStoreContext);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const handleSignout = async () => {
        await auth.signOut();
        await usersStoreCtx.setAuthorized(null);
    }

    const containsEmoji = (word: string) => {
        return word.match(/^\p{Emoji_Presentation}+/gu);
    };
    const getFirstEmoji = (word: string) => {
        const wordEmoji = containsEmoji(word);

        if (wordEmoji && wordEmoji[0]) {
            return wordEmoji[0];
        }

        return '';
    };
    const getFirstLetter = (word: string, count = 1) => {
        const emoji = getFirstEmoji(word);

        if (emoji.length) {
            return emoji;
        }

        return word.substring(0, count).toUpperCase();
    };
    const getUserInitials = (name: string) => {
        // Ruslan -> RU
        // Ruslan Tukalenko -> RT
        // Ruslan Tukalenko 🇨🇾 -> RT
        // Ruslan Tukalenko🇨🇾 -> RT
        // Ruslan 🇨🇾 -> R🇨🇾
        // Ruslan 🇨🇾 Tukalenko -> R🇨🇾
        // Ruslan 🇨🇾Tukalenko -> R🇨🇾
        // 🇨🇾 Ruslan ->  🇨🇾R
        // 🇨🇾 Ruslan Tukalenko -> 🇨🇾R

        const words = name
            .trim()
            .split(' ')
            .filter(word => !!word);

        if (words.length === 0) {
            words.push('');
        }

        if (words.length > 1) {
            return getFirstLetter(words[0]) + getFirstLetter(words[1]);
        }

        return getFirstLetter(words[0], 2);
    };

    const user = usersStoreCtx.userAuthorized && usersStoreCtx.userAuthorized.providerData && usersStoreCtx.userAuthorized.providerData[0];
    const isAdmin = usersStoreCtx.isAdmin();

    return (<Navbar color={'secondary'} dark={true} expand={'md'}>
            <NavbarBrand href="/">Selecta CRM</NavbarBrand>
            <Nav className="me-auto" navbar>
                <NavItem>
                    <NavLink to={'/estates'} className={({ isActive }) => isActive ? 'active' : ''}>Real estates</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to={'/clients'} className={({ isActive }) => isActive ? 'active' : ''}>Clients</NavLink>
                </NavItem>
                {
                    usersStoreCtx.checkPermissionAccess('leads.access') && <NavItem>
                        <NavLink to={'/leads'} className={({ isActive }) => isActive ? 'active' : ''}>Leads</NavLink>
                    </NavItem>
                }
            </Nav>
            <NavbarToggler onClick={toggle} />
            <Collapse navbar>
                <Dropdown style={{marginLeft: 'auto'}} isOpen={isOpen} toggle={toggle}>
                    <DropdownToggle caret>{getUserInitials(user.displayName || user.email)}</DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem header>
                            {user.displayName || user.email}
                            <Badge className="ml-8" color="primary">{usersStoreCtx.userPreferences.role}</Badge>
                        </DropdownItem>
                        <DropdownItem divider />
                        {
                            usersStoreCtx.checkPermissionAccess('provinces.access') && <DropdownItem>
                                <NavLink to={'/provinces'} className={({ isActive }) => isActive ? 'active' : ''}>Manage provinces</NavLink>
                            </DropdownItem>
                        }
                        {
                            usersStoreCtx.checkPermissionAccess('users.access') && <DropdownItem>
                                <NavLink to={'/users'} className={({ isActive }) => isActive ? 'active' : ''}>Manage users</NavLink>
                            </DropdownItem>
                        }
                        <DropdownItem divider />
                        <DropdownItem onClick={handleSignout}>Sign Out</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </Collapse>
        </Navbar>
    );
}

export default Header;
