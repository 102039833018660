import React, {useContext, useEffect, useState} from 'react';
import './Clients.scss';
import {observer} from 'mobx-react';
import {
    Alert,
    Button,
    Card,
    Col,
    Container,
    Input,
    Row,
    Table
} from 'reactstrap';
import {useNavigate, useOutletContext} from 'react-router-dom';
import moment from 'moment';
import {clientsStoreContext, clientStatuses, ClientStatusOptions} from '../../store/components/clientsStore';
import Select from 'react-select';
import {clone} from '../../utils/functions';

type Filter = {
    status: ClientStatusOptions;
    phone: string;
    fullName: string;
}

const Clients = observer(() => {
    const {setClientModalProps} = useOutletContext<any>();
    const clientsStoreCtx = useContext(clientsStoreContext);
    const [clients, setClients] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [filterState, setFilterState] = useState<Filter>({
        status: clientStatuses[0],
        phone: '',
        fullName: '',
    });
    const navigate = useNavigate();
    useEffect(() => {
        if (!isLoading) {
            return;
        }
        const params = clone(filterState);
        params.statuses = params.status.id === 0 ? [] : [params.status.value];
        clientsStoreCtx.loadStore(params).then((result) => {
            setClients(result);
            setLoading(false);
        });
    }, [clientsStoreCtx, isLoading]);

    const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        setFilterState({...filterState, ...{[field]: event.target.value}});
    }

    const handleChangeStatus = (item: ClientStatusOptions) => {
        setFilterState({...filterState, ...{status: item}});
        handleApplyFilter(null);
    }

    const handleApplyFilter = (event: React.FormEvent | null) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        setLoading(true);
    }

    const handleResetFilter = () => {
        setFilterState({
            status: clientStatuses[0],
            phone: '',
            fullName: '',
        });
        handleApplyFilter(null);
    }

    const handleNavigateToClientDetails = (id: string) => {
        navigate(`/client/${id}`);
    }

    const handleOpenCreateClientModal = () => {
        setClientModalProps({lead: null, isOpen: true});
    }

    return (<Container>
        <Row className={'leads'}>
            <Col sm="12">
                <Card body>
                    <form onSubmit={handleApplyFilter}>
                        <Row className="real-estates-filter align-items-end">
                            <Col sm="2">
                                <label htmlFor="fullName" className="form-label">Name</label>
                                <Input
                                    bsSize="sm"
                                    id="fullName"
                                    name="fullName"
                                    type="text"
                                    value={filterState.fullName}
                                    onChange={(event) => handleChangeTextField(event, 'fullName')}
                                />
                            </Col>
                            <Col sm="2">
                                <label htmlFor="phone" className="form-label">Phone</label>
                                <Input
                                    bsSize="sm"
                                    id="phone"
                                    name="phone"
                                    type="text"
                                    value={filterState.phone ? +filterState.phone : ''}
                                    onChange={(event) => handleChangeTextField(event, 'phone')}
                                />
                            </Col>
                            <Col sm="4">
                                <label htmlFor="status" className="form-label">Status</label>
                                <Select
                                    styles={{
                                        // @ts-ignore
                                        control: (provided, state) => ({
                                            ...provided,
                                            minHeight: '31px',
                                            height: '31px',
                                            // @ts-ignore
                                            background: state.selectProps.value ? state.selectProps.value.background : '#ffffff',
                                        }),
                                        valueContainer: (provided, state) => ({
                                            ...provided,
                                            height: '31px',
                                            padding: '0 6px',
                                            // @ts-ignore
                                            color: state.selectProps.value ? state.selectProps.value.color : '#ffffff'
                                        }),
                                        multiValue: (provided) => ({
                                            ...provided,
                                            marginTop: '0'
                                        }),
                                        input: (provided, state) => ({
                                            ...provided,
                                            margin: '0px',
                                        }),
                                        indicatorSeparator: state => ({
                                            display: 'none',
                                        }),
                                        indicatorsContainer: (provided, state) => ({
                                            ...provided,
                                            height: '31px',
                                        }),
                                        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                                            ...styles,
                                            background: data.background,
                                            color: data.color
                                        }),
                                        singleValue: (provided,state) => ({
                                            ...provided,
                                            // @ts-ignore
                                            color: state.selectProps.value ? state.selectProps.value.color : '#000000'
                                        }),
                                    }}
                                    isClearable={false}
                                    options={clientStatuses}
                                    id="status"
                                    // @ts-ignore
                                    onChange={handleChangeStatus}
                                    value={filterState.status}
                                    placeholder={''}
                                />
                            </Col>
                            <Col className="col-auto">
                                <Button hidden size="sm" type="submit" color="success" onClick={handleApplyFilter}>
                                    Apply
                                </Button>
                                <Button size="sm" type="button" onClick={handleResetFilter}>
                                    Reset
                                </Button>
                            </Col>
                            <Col style={{textAlign: 'right'}}>
                                <Button size="sm" color={'primary'} type={'button'} onClick={handleOpenCreateClientModal}>Create client</Button>
                            </Col>
                        </Row>
                    </form>
                    {
                        isLoading && <div>Loading...</div>
                    }
                    {
                        !isLoading && (!clients || !clients.length)
                            ? <div>
                                <Alert color="warning">
                                    No clients for display
                                </Alert>
                            </div>
                            : !isLoading && <Table bordered hover responsive size={'sm'}>
                            <thead>
                            <tr>
                                <th style={{width: 126}}>Type</th>
                                <th style={{width: 240}}>Name</th>
                                <th style={{width: 100}}>Phone</th>
                                <th>Email</th>
                                <th style={{width: 106}}>Province</th>
                                <th style={{width: 136}}>Price</th>
                                <th style={{width: 146}}>Created</th>
                                <th style={{width: 106}}>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                clients && clients.length > 0 && clients.map((client: any) => {
                                    const status = clientStatuses.find((option) => option.value === client.status);
                                    return (
                                        <tr key={client.id} onClick={() => handleNavigateToClientDetails(client.id)}>
                                            <td style={{textTransform: 'capitalize'}}>{client.type.replace('_', ' ')}</td>
                                            <td>{client.fullName}</td>
                                            <td>{client.phoneCode}{client.phone}</td>
                                            <td>{client.email}</td>
                                            <td>{client.province}</td>
                                            <td>{client.price}</td>
                                            <td>{client.createdAt ? moment(client.createdAt).format('DD MMM YYYY, hh:mm') : ''}</td>
                                            <td>
                                                <div className="badge-custom" style={{backgroundColor: status?.background, color: status?.color}}>{client.status}</div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                    }
                </Card>
            </Col>
        </Row>
    </Container>)
})

export default Clients;
