import React, {useContext, useState} from 'react';
import {Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import './ClientModal.scss';
import {Lead, leadsStoreContext} from '../../store/components/leadsStore';
import {Client, clientClearState, clientsStoreContext, ClientType} from '../../store/components/clientsStore';
import {clone} from '../../utils/functions';
import FieldPhone from '../FieldPhone/FieldPhone';
import {ContentEditableEvent} from 'react-simple-wysiwyg';
import {Province, provinceStoreContext} from '../../store/components/provinceStore';
import {useNavigate} from 'react-router-dom';
import RealEstatesSelect from '../RealEstatesSelect/RealEstatesSelect';
import {SingleValue} from 'react-select';
import {RealEstateType} from '../../store/components/realEstatesStore';
import ClientTypesSelect from '../ClientTypesSelect/ClientTypesSelect';
import ProvincesSelect from '../ProvincesSelect/ProvincesSelect';

type ClientModalProps = {
    lead: Lead | null;
    onClose: () => void;
};

const ClientModal: React.FC<ClientModalProps> = (props) => {
    const provinceStoreCtx = useContext(provinceStoreContext);
    const clientsStoreCtx = useContext(clientsStoreContext);
    const leadsStoreCtx = useContext(leadsStoreContext);
    const navigate = useNavigate();
    const [client, setClient] = useState<Client>({...clone(clientClearState), ...(props.lead ? props.lead : {})});
    const [provinces] = useState<Province[]>(provinceStoreCtx.getProvinces());

    const close = () => props.onClose();
    const merge = (data: any) => {
        setClient({...client, ...data});
    }

    const handleCreateClient = async () => {
        const data = clone(client);
        for (let key in data) {
            data[key] = data[key] ? data[key] : null;
        }
        delete data.id;
        delete data.city;
        delete data.description;
        delete data.createdAt;
        try {
            const cl = await clientsStoreCtx.create(data);
            if (props.lead) {
                // @ts-ignore
                await leadsStoreCtx.update(props.lead.id, {...props.lead, ...{status: 'converted'}});
            }
            close();
            navigate(`/client/${cl.id}`);
        } catch (err) {
        }
    };

    const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement> | ContentEditableEvent, field: string) =>
        merge({[field]: event.target.value});
    const handleChangeRealEstateType = (item: SingleValue<RealEstateType>) =>
        merge({realEstateType: item ? item.value : null});
    const handleChangeClientType = (item: SingleValue<ClientType>) =>
        merge({type: item ? item.value : null});
    const handleChangeProvince = (item: SingleValue<Province>) =>
        merge({province: item ? item.name : null});

    return (<Modal isOpen={true} toggle={close}>
        <ModalHeader toggle={close}>{props.lead ? 'Convert lead to client' : 'Create client'}</ModalHeader>
        <ModalBody>
            <Row>
                <Col sm={6}>
                    <label htmlFor="fullName" className="form-label">Name *</label>
                    <Input
                        bsSize="sm"
                        id="fullName"
                        name="fullName"
                        type="text"
                        value={client.fullName ?? ''}
                        onChange={(event) => handleChangeTextField(event, 'fullName')}
                    />
                </Col>
                <Col sm={6}>
                    <label htmlFor="type" className="form-label">Type *</label>
                    <ClientTypesSelect type={client.type || null} onChange={handleChangeClientType}/>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <label htmlFor="province" className="form-label">Province</label>
                    <ProvincesSelect isClearable={true} province={client.province || null} provinces={provinces} onChange={handleChangeProvince}/>
                </Col>
                <Col sm={6}>
                    <label htmlFor="realEstateType" className="form-label">Real estate type</label>
                    <RealEstatesSelect isClearable={true} type={client.realEstateType || null} onChange={handleChangeRealEstateType}/>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <label htmlFor="price" className="form-label">Price</label>
                    <Input
                        bsSize="sm"
                        id="price"
                        name="price"
                        type="text"
                        value={client.price ?? ''}
                        onChange={(event) => handleChangeTextField(event, 'price')}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <label htmlFor="phone" className="form-label">Phone *</label>
                    <FieldPhone
                        id="phone"
                        type="tel"
                        placeholder={'_________'}
                        value={client.phone ?? ''}
                        onChange={(event) => handleChangeTextField(event, 'phone')}
                        name={'phone'}
                        maxLength={9}
                        phoneCode={client.phone ?? ''}
                        onChangePhoneCode={(phoneCode) => merge({phoneCode: phoneCode})}
                    />
                </Col>
                <Col sm={6}>
                    <label htmlFor="email" className="form-label">Email</label>
                    <Input
                        bsSize="sm"
                        id="email"
                        name="email"
                        type="text"
                        value={client.email ?? ''}
                        onChange={(event) => handleChangeTextField(event, 'email')}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <label htmlFor="nie" className="form-label">NIE</label>
                    <Input
                        bsSize="sm"
                        id="nie"
                        name="nie"
                        type="text"
                        value={client.nie ?? ''}
                        onChange={(event) => handleChangeTextField(event, 'nie')}
                    />
                </Col>
                <Col sm={6}>
                    <label htmlFor="passport" className="form-label">Passport</label>
                    <Input
                        bsSize="sm"
                        id="passport"
                        name="passport"
                        type="text"
                        value={client.passport ?? ''}
                        onChange={(event) => handleChangeTextField(event, 'passport')}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <label htmlFor="notes" className="form-label">Notes</label>
                    <Input
                        id="notes"
                        name="notes"
                        type="textarea"
                        value={client.notes ?? ''}
                        onChange={(event) => handleChangeTextField(event, 'notes')}
                    />
                </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
            <Button disabled={!client.fullName || !client.phone || !client.type} color="primary"
                    onClick={handleCreateClient}>
                {props.lead ? 'Convert' : 'Create'}
            </Button>{' '}
            <Button color="secondary" onClick={close}>
                Cancel
            </Button>
        </ModalFooter>
    </Modal>);
}

export default ClientModal;
