import {action, makeObservable, observable} from 'mobx';
import {createContext} from 'react';
import {api} from '../../services/Api';
import {sortBy} from '../../utils/functions';

export const leadClearState: Lead = {
    province: '',
    realEstateType: '',
    price: '',
    fullName: '',
    phone: '',
    phoneCode: '+34',
    email: '',
    description: '',
    notes: '',
    type: '',
    status: 'new'
}
class Store {
    modified: number = 11111111;
    lead: Lead = {...{}, ...leadClearState};
    leads: Lead[] = [];

    constructor() {
        makeObservable(this, {
            lead: observable,
            leads: observable,
            set: action,
            get: action,
            getLead: action,
            loadStore: action,
        });
    }

    get() {
        return this.leads;
    }

    set(leads: Lead[]) {
        this.leads = leads;
    }

    setModified() {
        this.modified = +new Date();
    }

    async getLead(id: string) {
        const lead = this.leads.find((ld) => ld.id === id);
        if (lead) {
            return lead;
        }

        try {
            const lead = await api.request('GET', `/lead/${id}`);
            if (lead.status === 404) {
                return Promise.reject(lead);
            }
            return lead;
        } catch (err) {
            return Promise.reject(err);
        }
    }

    async create(data: Lead) {
        try {
            return await api.request('POST', '/lead', data);
        } catch (err) {}
    }

    async update(id: string, lead: Lead) {
        try {
            return await api.request('PUT', `/lead/${id}`, lead);
        } catch (err) {}
    }

    async loadStore(params: {phone: string, fullName: string, statuses: string[]}) {
        try {
            const {phone, fullName, statuses} = params;
            let query = new URLSearchParams({phone, fullName}).toString();
            if (statuses) {
                statuses.forEach((status) => query += `&statuses[]=${status}`);
            }
            const leads = await api.request('GET', '/leads?' + query);
            this.set(leads);
            return leads;
        } catch (err) {
        }
    }
}

export type Lead = {
    id?: string;
    realEstateType?: string;
    province?: string;
    city?: string;
    fullName: string;
    phone: string;
    phoneCode: string;
    email?: string;
    description?: string;
    notes?: string;
    type?: string;
    status: string;
    price: string;
    createdAt?: string;
}

export type LeadsStore = {
    lead: Lead;
    leads: Lead[];
    set: (leads: Lead[]) => void,
    setModified: () => void,
    get: () => Lead[],
    getLead: (id: string) => Promise<Lead>,
    update: (id: string, lead: Lead) => Promise<Lead>;
    loadStore: (params: {phone: string, fullName: string, statuses: string[]}) => Promise<Lead[]>,
}

export const leadsStore = new Store();
export const leadsStoreContext = createContext(leadsStore);
