import React, {useContext, useState} from 'react';
import {Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import './LeadModal.scss';
import {Lead, leadsStoreContext, leadClearState} from '../../store/components/leadsStore';
import {clone} from '../../utils/functions';
import FieldPhone from '../FieldPhone/FieldPhone';
import {ContentEditableEvent} from 'react-simple-wysiwyg';
import {Province, provinceStoreContext} from '../../store/components/provinceStore';
import {ClientType} from '../../store/components/clientsStore';
import RealEstatesSelect from '../RealEstatesSelect/RealEstatesSelect';
import {SingleValue} from 'react-select';
import {RealEstateType} from '../../store/components/realEstatesStore';
import ClientTypesSelect from '../ClientTypesSelect/ClientTypesSelect';
import ProvincesSelect from '../ProvincesSelect/ProvincesSelect';

type ClientModalProps = {
    onClose: () => void;
};

const LeadModal: React.FC<ClientModalProps> = (props) => {
    const provinceStoreCtx = useContext(provinceStoreContext);
    const leadsStoreCtx = useContext(leadsStoreContext);
    const [lead, setLead] = useState<Lead>(clone(leadClearState));
    const [provinces] = useState<Province[]>(provinceStoreCtx.getProvinces());
    const close = () => props.onClose();
    const merge = (data: any) => setLead({...lead, ...data});
    const handleCreateLead = async () => {
        const data = clone(lead);
        for (let key in data) {
            data[key] = data[key] ? data[key] : null;
        }
        try {
            await leadsStoreCtx.create(data);
            leadsStoreCtx.setModified();
            props.onClose();
        } catch (err) {
        }
    };

    const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement> | ContentEditableEvent, field: string) =>
        merge({[field]: event.target.value});
    const handleChangeRealEstateType = (item: SingleValue<RealEstateType>) =>
        merge({realEstateType: item ? item.value : null});
    const handleChangeClientType = (item: SingleValue<ClientType>) =>
        merge({type: item ? item.value : null});
    const handleChangeProvince = (item: SingleValue<Province>) =>
        merge({province: item ? item.name : null});

    return (<Modal isOpen={true} toggle={close}>
        <ModalHeader toggle={close}>Create lead</ModalHeader>
        <ModalBody>
            <Row>
                <Col sm={6}>
                    <label htmlFor="fullName" className="form-label">Name *</label>
                    <Input
                        bsSize="sm"
                        id="fullName"
                        name="fullName"
                        type="text"
                        value={lead.fullName ?? ''}
                        onChange={(event) => handleChangeTextField(event, 'fullName')}
                    />
                </Col>
                <Col sm={6}>
                    <label htmlFor="type" className="form-label">Type *</label>
                    <ClientTypesSelect isClearable={true} type={lead.type || null} onChange={handleChangeClientType}/>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <label htmlFor="province" className="form-label">Province</label>
                    <ProvincesSelect isClearable={true} province={lead.province || null} provinces={provinces} onChange={handleChangeProvince}/>
                </Col>
                <Col sm={6}>
                    <label htmlFor="city" className="form-label">City</label>
                    <Input
                        bsSize="sm"
                        id="city"
                        name="city"
                        type="text"
                        value={lead.city ?? ''}
                        onChange={(event) => handleChangeTextField(event, 'city')}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <label htmlFor="realEstateType" className="form-label">Real estate type</label>
                    <RealEstatesSelect isClearable={true} type={lead.realEstateType || null} onChange={handleChangeRealEstateType}/>
                </Col>
                <Col sm={6}>
                    <label htmlFor="price" className="form-label">Price</label>
                    <Input
                        bsSize="sm"
                        id="price"
                        name="price"
                        type="text"
                        value={lead.price ?? ''}
                        onChange={(event) => handleChangeTextField(event, 'price')}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <label htmlFor="phone" className="form-label">Phone *</label>
                    <FieldPhone
                        id="phone"
                        type="tel"
                        placeholder={'_________'}
                        value={lead.phone ?? ''}
                        onChange={(event) => handleChangeTextField(event, 'phone')}
                        name={'phone'}
                        maxLength={9}
                        phoneCode={lead.phone ?? ''}
                        onChangePhoneCode={(phoneCode) => merge({phoneCode: phoneCode})}
                    />
                </Col>
                <Col sm={6}>
                    <label htmlFor="email" className="form-label">Email</label>
                    <Input
                        bsSize="sm"
                        id="email"
                        name="email"
                        type="text"
                        value={lead.email ?? ''}
                        onChange={(event) => handleChangeTextField(event, 'email')}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <label htmlFor="notes" className="form-label">Notes</label>
                    <Input
                        id="notes"
                        name="notes"
                        type="textarea"
                        value={lead.notes ?? ''}
                        onChange={(event) => handleChangeTextField(event, 'notes')}
                    />
                </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
            <Button disabled={!lead.fullName || !lead.phone || !lead.type} color="primary"
                    onClick={handleCreateLead}>
                Create
            </Button>{' '}
            <Button color="secondary" onClick={close}>
                Cancel
            </Button>
        </ModalFooter>
    </Modal>);
}

export default LeadModal;
