import React, { CSSProperties } from 'react';
import Select, {SingleValue} from 'react-select';
import './RealEstatesSelect.scss';
import {
    realEstateCommercialTypes,
    realEstateResidentialTypes,
    RealEstateType
} from '../../store/components/realEstatesStore';

type BuyStatusProps = {
    type?: string | null;
    isClearable?: boolean;
    onChange: (item: SingleValue<RealEstateType>) => void
}

interface GroupedOption {
    readonly label: string;
    readonly options: readonly RealEstateType[];
}

export const groupedOptions: readonly GroupedOption[] = [
    {
        label: 'Residential',
        options: realEstateResidentialTypes,
    },
    {
        label: 'Commercial',
        options: realEstateCommercialTypes,
    },
];

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
const groupBadgeStyles: CSSProperties = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

const formatGroupLabel = (data: GroupedOption) => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

const RealEstatesSelect: React.FC<BuyStatusProps> = (props) => {
    return (<Select<RealEstateType, false, GroupedOption>
        // defaultValue={colourOptions[1]}
        options={groupedOptions}
        formatGroupLabel={formatGroupLabel}
        placeholder={''}
        styles={{
            // @ts-ignore
            control: (provided, state) => ({
                ...provided,
                minHeight: '31px',
                height: '31px',
                // @ts-ignore
                background: state.selectProps.value ? state.selectProps.value.background : '#ffffff',
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                height: '31px',
                padding: '0 6px',
                // @ts-ignore
                color: state.selectProps.value ? state.selectProps.value.color : '#ffffff'
            }),
            multiValue: (provided) => ({
                ...provided,
                marginTop: '0'
            }),
            input: (provided, state) => ({
                ...provided,
                margin: '0px',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '31px',
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                ...styles,
            }),
            singleValue: (provided,state) => ({
                ...provided,
                // @ts-ignore
                color: state.selectProps.value ? state.selectProps.value.color : '#000000'
            }),
        }}
        isClearable={props.isClearable || false}
        onChange={props.onChange}
        value={realEstateResidentialTypes.find((t) => t.value === props.type) || realEstateCommercialTypes.find((t) => t.value === props.type) || null}
    />)
}

export default RealEstatesSelect;
