import React, {useContext, useEffect, useState} from 'react';
import {Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import './RealEstateModal.scss';
import {Client, clientsStoreContext} from '../../store/components/clientsStore';
import {clone} from '../../utils/functions';
import {ContentEditableEvent} from 'react-simple-wysiwyg';
import {Province, provinceStoreContext} from '../../store/components/provinceStore';
import {useNavigate} from 'react-router-dom';
import {
    Municipality,
    realEstateClearState,
    RealEstateNew,
    realEstatesStoreContext, RealEstateType
} from '../../store/components/realEstatesStore';
import MunicipalitiesCombobox from '../MunicipalitiesCombobox/MunicipalitiesCombobox';
import {SingleValue} from 'react-select';
import RealEstatesSelect from '../RealEstatesSelect/RealEstatesSelect';

type ClientModalProps = {
    client: Client | null;
    onClose: () => void;
};

const RealEstateModal: React.FC<ClientModalProps> = (props) => {
    const provinceStoreCtx = useContext(provinceStoreContext);
    const realEstatesStoreCtx = useContext(realEstatesStoreContext);
    const clientsStoreCtx = useContext(clientsStoreContext);
    const navigate = useNavigate();
    const [realEstate, setRealEstate] = useState<RealEstateNew>(clone(realEstateClearState));
    const [provinces] = useState<Province[]>(provinceStoreCtx.getProvinces());

    const close = () => props.onClose();
    const merge = (data: any) => setRealEstate({...realEstate, ...data});

    useEffect(() => {
        const client = props.client;
        if (client) {
            merge({
                privacy: true,
                province: client.province,
                municipality: null,
                // @ts-ignore
                client: props.client.id,
                price: null,
                realEstateType: '',
                notes: null,
                status: 'new',
            });
        }
    }, [props.client]);

    const handleCreateRealEstate = async () => {
        const data = clone(realEstate);
        for (let key in data) {
            data[key] = data[key] ? data[key] : null;
        }
        try {
            const re = await realEstatesStoreCtx.create(data);
            // @ts-ignore
            await clientsStoreCtx.update(props.client.id, {...props.client, ...{realEstates: props.client.realEstates ? props.client.realEstates.concat([re.id]) : [re.id]}});
            close();
            navigate(`/realEstate/${re.id}`);
        } catch (err) {
        }
    };

    const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement> | ContentEditableEvent, field: string) =>
        merge({[field]: event.target.value});

    const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>, field: string) =>
        merge({[field]: event.target.value});

    const handleChangeRealEstateType = (item: SingleValue<RealEstateType>) => merge({realEstateType: item ? item.value : null});

    const handleSelectMunicipality = (municipality: Municipality | null) =>
        merge({municipality: municipality ? municipality.name : ''});

    return (<Modal isOpen={true} toggle={close}>
        <ModalHeader toggle={close}>{'Create real estate'}</ModalHeader>
        <ModalBody>
            <Row>
                <Col sm={6}>
                    <label htmlFor="province" className="form-label">Province</label>
                    <Input
                        bsSize="sm"
                        id="province"
                        name="province"
                        type="select"
                        onChange={(event) => handleChangeSelect(event, 'province')}
                    >
                        {provinces.map((province) => {
                            return (<option value={province.name} key={'province_' + province.name}
                                            selected={realEstate.province === province.name}>
                                {province.name}
                            </option>)
                        })}
                    </Input>
                </Col>
                <Col sm={6}>
                    <label htmlFor="municipality" className="form-label">Municipality</label>
                    <MunicipalitiesCombobox
                        initBy={realEstate.municipality ? realEstate.municipality : null}
                        province={provinceStoreCtx.getProvince(realEstate.province ?? '')}
                        onSelect={handleSelectMunicipality}
                        icon={'icon-location'}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <label htmlFor="realEstateType" className="form-label">Real estate type</label>
                    <RealEstatesSelect type={realEstate.realEstateType || null} onChange={handleChangeRealEstateType}/>
                </Col>
                <Col sm={6}>
                    <label htmlFor="price" className="form-label">Price</label>
                    <Input
                        bsSize="sm"
                        id="price"
                        name="price"
                        type="text"
                        value={realEstate.price ?? ''}
                        onChange={(event) => handleChangeTextField(event, 'price')}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <label htmlFor="notes" className="form-label">Notes</label>
                    <Input
                        id="notes"
                        name="notes"
                        type="textarea"
                        value={realEstate.notes ?? ''}
                        onChange={(event) => handleChangeTextField(event, 'notes')}
                    />
                </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
            <Button color="primary"
                    onClick={handleCreateRealEstate}>
                Create
            </Button>{' '}
            <Button color="secondary" onClick={close}>
                Cancel
            </Button>
        </ModalFooter>
    </Modal>);
}

export default RealEstateModal;
