import React, {useContext} from 'react';
import './Users.scss';
import {Button, Col, Container, Row, Table} from 'reactstrap';
import {usersStoreContext, UserWithAuth} from '../../store/components/usersStore';
import {Link, useNavigate} from 'react-router-dom';

function Users() {
    const usersStoreCtx = useContext(usersStoreContext);
    const navigate = useNavigate();
    const handleNavigateToManageUser = (user: UserWithAuth) => {
        navigate(`/users/${user.id}`);
    }
    return (
        <Container style={{marginTop: '24px'}}>
            <Row>
                <Col sm={{
                    size: 12
                }}>
                    <Table bordered hover responsive size={'sm'}>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Role</th>
                            <th>Email</th>
                            <th>Deactivated</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            usersStoreCtx.users.map((user: UserWithAuth) => (
                                <tr key={user.email} onClick={() => handleNavigateToManageUser(user)}>
                                    <td>{user.id}</td>
                                    <td>{user.role}</td>
                                    <td>{user.email}</td>
                                    <td>{user.disabled  ? 'Yes' : 'No'}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Link to={'/users/create'}><Button color={'primary'} size={'sm'}>Add user</Button></Link>
            </Row>
        </Container>
    )
}

export default Users;
