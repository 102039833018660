import React, {SyntheticEvent, useContext, useEffect, useState} from 'react';
import './User.scss';
import {Button, Col, Container, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import {usersStoreContext, UserWithAuth} from '../../store/components/usersStore';
import {useNavigate, useParams} from 'react-router-dom';
import {provinceStoreContext} from '../../store/components/provinceStore';

type UserProps = {
    userId?: string;
}

const defaultPermissions: [] = [];
const defaultProvincesAccess = ['Valencia', 'Barcelona']

const User: React.FC<UserProps> = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const usersStoreCtx = useContext(usersStoreContext);
    const provincesStoreCtx = useContext(provinceStoreContext);
    const provinces = provincesStoreCtx.getProvinces();
    const [isManageUser, setManageUser] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [role, setRole] = useState('admin');
    const [permissions, setPermissions] = useState<string[]>([]);
    const [provincesAccess, setProvincesAccess] = useState<any[]>([]);

    useEffect(() => {
        if (!params.id) {
            setPermissions(defaultPermissions);
            setProvincesAccess(defaultProvincesAccess);
            return;
        }
        const userState = usersStoreCtx.getUser(params.id);
        if (userState) {
            setEmail(userState.email);
            setRole(userState.role);
            setManageUser(true);
            setPermissions(userState.permissions);
            setProvincesAccess(userState.provinces);
        }
    }, [params.id, usersStoreCtx]);

    const handleSubmitForm = async (event: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
        event.preventDefault();
        event.stopPropagation();

        if (isManageUser) {
            if (!email || !provincesAccess.length || !params.id) {
                return;
            }
            console.log(role, permissions, provincesAccess)
            try {
                await usersStoreCtx.updateUser(params.id, {role, permissions: permissions, provinces: provincesAccess});
                navigate('/users');
            } catch (err) {
                console.log('[User.tsx][handleSubmitForm] crate user error: ', err);
            }
        } else {
            if ((!email || !password || !passwordConfirm) || (password !== passwordConfirm) || !provincesAccess.length) {
                return;
            }
            try {
                await usersStoreCtx.createUser({email, password, role, permissions: permissions, provinces: provincesAccess});
                navigate('/users');
            } catch (err) {
                console.log('[User.tsx][handleSubmitForm] crate user error: ', err);
            }
        }
    }

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);
    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);
    const handleChangePasswordConfirm = (event: React.ChangeEvent<HTMLInputElement>) => setPasswordConfirm(event.target.value);
    const checkProvinceAccess = (name: string) => provincesAccess?.includes(name);
    const checkPermission = (permission: string) => permissions?.includes(permission);
    const handleChangePermissionAccess = (permission: string, access: boolean) => {
        if (access) {
            setPermissions(permissions.concat([permission]));
        } else {
            setPermissions(permissions.filter((perm) => perm !== permission));
        }
    }

    const handleChangeProvinceAccess = (province: string, access: boolean) => {
        if (access) {
            setProvincesAccess(provincesAccess.concat([province]));
        } else {
            setProvincesAccess(provincesAccess.filter((prov) => prov !== province));
        }
    }

    return (
        <Container style={{marginTop: '24px'}}>
            <Row>
                <Col sm={{
                    size: 12
                }}>
                    <Form onSubmit={handleSubmitForm}>
                        <Row>
                            <Col sm={{
                                size: 3
                            }}>
                                <FormGroup>
                                    <Label for="email">
                                        Email *
                                    </Label>
                                    <Input
                                        disabled={isManageUser}
                                        id="email"
                                        name="email"
                                        type="email"
                                        value={email}
                                        onChange={handleChangeEmail}
                                    />
                                </FormGroup>
                            </Col>
                            {
                                !isManageUser && <Col sm={{
                                    size: 3
                                }}>
                                    <FormGroup>
                                        <Label for="password">
                                            Password *(min 6 characters)
                                        </Label>
                                        <Input
                                            id="password"
                                            name="password"
                                            type="password"
                                            value={password}
                                            onChange={handleChangePassword}
                                        />
                                    </FormGroup>
                                </Col>
                            }
                            {
                                !isManageUser && <Col sm={{
                                    size: 3
                                }}>
                                    <FormGroup>
                                        <Label for="passwordConfirm">
                                            Password confirm *
                                        </Label>
                                        <Input
                                            id="passwordConfirm"
                                            name="passwordConfirm"
                                            type="password"
                                            value={passwordConfirm}
                                            onChange={handleChangePasswordConfirm}
                                        />
                                    </FormGroup>
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Label for="passwordConfirm">
                                Provinces access *
                            </Label>
                            <Col sm={3}>
                                {
                                    provinces.map((province) => {
                                        return (<FormGroup check key={province.name}>
                                            <Input
                                                type="checkbox"
                                                id={province.name}
                                                checked={checkProvinceAccess(province.name)}
                                                onChange={(event) => handleChangeProvinceAccess(province.name, event.target.checked)}
                                            />
                                            <Label check for={province.name}>
                                                {province.name}
                                            </Label>
                                        </FormGroup>)
                                    })
                                }
                            </Col>
                            <Col sm={3}>
                                <FormGroup check>
                                    <Input
                                        type="checkbox"
                                        id="provinces.access"
                                        checked={checkPermission('provinces.access')}
                                        onChange={(event) => handleChangePermissionAccess('provinces.access', event.target.checked)}
                                    />
                                    <Label check for="provinces.access">
                                        Provinces page access
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup check>
                                    <Input
                                        type="checkbox"
                                        id="users.access"
                                        checked={checkPermission('users.access')}
                                        onChange={(event) => handleChangePermissionAccess('users.access', event.target.checked)}
                                    />
                                    <Label check for="users.access">
                                        Users page access
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup check>
                                    <Input
                                        type="checkbox"
                                        id="leads.access"
                                        checked={checkPermission('leads.access')}
                                        onChange={(event) => handleChangePermissionAccess('leads.access', event.target.checked)}
                                    />
                                    <Label check for="leads.access">
                                        Leads page access
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mt-32">
                            <Col sm={{size: 3}}>
                                <Button color={'primary'} size={'sm'}>
                                    {isManageUser ? 'Update' : 'Create'} user
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default User;
