import React from 'react';
import Select, {SingleValue} from 'react-select';
import {ClientType, clientTypes} from '../../store/components/clientsStore';

type ClientTypesSelectProps = {
    type?: string | null;
    isClearable?: boolean;
    onChange: (item: SingleValue<ClientType>) => void;
}

const ClientTypesSelect: React.FC<ClientTypesSelectProps> = (props) => {
    return (<Select
        styles={{
            // @ts-ignore
            control: (provided, state) => ({
                ...provided,
                minHeight: '31px',
                height: '31px',
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                height: '31px',
                padding: '0 6px',
            }),
            multiValue: (provided) => ({
                ...provided,
                marginTop: '0'
            }),
            input: (provided, state) => ({
                ...provided,
                margin: '0px',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '31px',
            })
        }}
        isClearable={props.isClearable || false}
        options={clientTypes}
        onChange={props.onChange}
        value={clientTypes.find((clientType) => clientType.value === props.type) || null}
        placeholder={''}
    />)
}

export default ClientTypesSelect;
