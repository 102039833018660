import {action, makeObservable, observable} from 'mobx';
import {createContext} from 'react';
import {api} from '../../services/Api';

export const clientClearState: Client = {
    fullName: '',
    phone: '',
    phoneCode: '',
    email: '',
    province: '',
    type: '',
    realEstateType: '',
    price: '',
    nie: '',
    passport: '',
    notes: '',
    status: '',
}

class RealEstatesStore {
    clients: Client[] = [];

    constructor() {
        makeObservable(this, {
            clients: observable,
            create: action,
            add: action,
            set: action,
            getClients: action,
            get: action,
            loadStore: action,
        });
    }

    getClients() {
        return this.clients;
    }

    set(clients: Client[]) {
        this.clients = clients;
    }

    add(client: Client) {
        this.clients.push(client);
    }

    async create(client: Client) {
        try {
            const cl = await api.request('POST', 'client', client);
            this.add(cl);
            return cl;
        } catch (err) {
            return Promise.reject(err);
        }
    }
    async get(id: string) {
        const client = this.clients.find((cl) => cl.id === id);
        if (client) {
            return client;
        }

        try {
            const client = await api.request('GET', `/client/${id}`);
            if (client.status === 404) {
                return Promise.reject(client);
            }
            return client;
        } catch (err) {
            return Promise.reject(err);
        }
    }

    async update(id: string, client: Client) {
        try {
            return await api.request('PUT', `/client/${id}`, client);
        } catch (err) {}
    }

    async loadStore(params: {phone: string, fullName: string, statuses: string[]}) {
        try {
            const {phone, fullName, statuses} = params;
            let query = new URLSearchParams({phone, fullName}).toString();
            if (statuses) {
                statuses.forEach((status) => query += `&statuses[]=${status}`);
            }
            const clients = await api.request('GET', '/clients?' + query);
            this.set(clients);
            return clients;
        } catch (err) {
        }
    }
}

export type Client = {
    id?: string;
    fullName: string;
    phone: string;
    phoneCode: string;
    email?: string;
    province: string;
    type: string;
    realEstateType?: string;
    realEstates?: string[];
    price?: string;
    nie: string;
    passport: string;
    notes: string;
    status: string;
    createdAt?: string;
}


export const clientTypes: ClientType[] = [
    {id: 1, label: 'Buyer', value: 'buyer', parentType: 'client'},
    {id: 2, label: 'Seller', value: 'seller', parentType: 'client'},
    {id: 3, label: 'Client Supplier', value: 'client_supplier', parentType: 'partner'},
    {id: 4, label: 'Development', value: 'development', parentType: 'partner'},
    {id: 5, label: 'Property Supplier', value: 'property_supplier', parentType: 'partner'},
];

export const clientStatuses: ClientStatusOptions[] = [
    { id: 0, value: 'all', label: 'All', background: '#ffffff', color: '#000000' },
    { id: 1, value: 'active', label: 'Active', background: '#3CB371', color: '#ffffff' },
    { id: 2, value: 'contacted', label: 'Contacted', background: '#6c757d', color: '#ffffff' },
    { id: 3, value: 'completed', label: 'Completed', background: '#f0ad4e', color: '#ffffff' },
    { id: 4, value: 'closed', label: 'Closed', background: '#be6464', color: '#ffffff' },
    { id: 5, value: 'declined', label: 'Declined', background: '#be6464', color: '#ffffff' },
    { id: 6, value: 'new', label: 'New', background: '#337ab7', color: '#ffffff' },
];

export type ClientType = {
    id: number;
    label: string;
    value: string;
    parentType: string;
};

export type ClientStatusOptions = {
    id: number;
    value: string;
    label: string;
    background: string;
    color: string;
}
export const clientsStore = new RealEstatesStore();
export const clientsStoreContext = createContext(clientsStore);
