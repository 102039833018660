import React from 'react';
import Select, {SingleValue} from 'react-select';
import {Province} from '../../store/components/provinceStore';

type ClientTypesSelectProps = {
    province?: string | null;
    provinces: Province[];
    isClearable?: boolean;
    onChange: (item: SingleValue<Province>) => void;
}

const ProvincesSelect: React.FC<ClientTypesSelectProps> = (props) => {
    return (<Select
        styles={{
            // @ts-ignore
            control: (provided, state) => ({
                ...provided,
                minHeight: '31px',
                height: '31px',
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                height: '31px',
                padding: '0 6px',
            }),
            multiValue: (provided) => ({
                ...provided,
                marginTop: '0'
            }),
            input: (provided, state) => ({
                ...provided,
                margin: '0px',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '31px',
            })
        }}
        isClearable={props.isClearable || false}
        options={props.provinces}
        onChange={props.onChange}
        value={props.provinces.find((province: Province) => province.name === props.province) || null}
        getOptionValue={(option) => option.name.toString()}
        getOptionLabel={(option) => option.name}
        placeholder={''}
    />)
}

export default ProvincesSelect;
