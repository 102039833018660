import React, {useContext, useEffect} from 'react';
import './Root.scss';
import {
    Routes,
    Route,
    HashRouter, Navigate,
} from 'react-router-dom';
import {observer, Provider as MobxProvider} from 'mobx-react';
import RealEstates from '../RealEstates/RealEstates';
import RealEstateDetails from '../RealEstateDetails/RealEstateDetails';
import Signin from '../Signin/Signin';
import {store, storeContext} from '../../store/store';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';
import ProtectedRoute from './Protected/ProtectedRoute';
import Clients from '../Clients/Clients';
import ClientDetails from '../ClientDetails/ClientDetails';
import Provinces from '../Provinces/Provinces';
import Users from '../Users/Users';
import User from '../User/User';
import Leads from '../Leads/Leads';

let isLoading = true;

const Root = observer(() => {
    const storeCtx = useContext(storeContext);

    useEffect(() => {
        if (isLoading) {
            isLoading = false;
        } else {
            return;
        }
    }, [storeCtx]);

    if (storeCtx.isLoading) {
        return null
    }
    return (
        <MobxProvider store={store}>
            <ErrorBoundary>
                <HashRouter>
                    <Routes>
                        <Route path="/signin" element={<Signin />} />
                        <Route element={<ProtectedRoute isAuthorized={storeCtx.usersStore.isAuthorized}/>}>
                            <Route path="*" element={<Navigate to="/estates" replace />}/>
                            <Route path="/estates" element={<RealEstates/>} />
                            <Route path="/estate/:id" element={<RealEstateDetails/>} />
                            <Route path="/clients" element={<Clients/>} />
                            <Route path="/client/:id" element={<ClientDetails/>} />
                            {
                                storeCtx.usersStore.checkPermissionAccess('leads.access') && <Route path="/leads" element={<Leads/>} />
                            }
                            {
                                storeCtx.usersStore.checkPermissionAccess('provinces.access') && <Route path="/provinces" element={<Provinces/>} />
                            }
                            {
                                storeCtx.usersStore.checkPermissionAccess('users.access') && <Route path="/users" element={<Users/>} />
                            }
                            {
                                storeCtx.usersStore.checkPermissionAccess('users.access') && <Route path="/users/create" element={<User/>} />
                            }
                            {
                                storeCtx.usersStore.checkPermissionAccess('users.access') && <Route path="/users/:id" element={<User/>} />
                            }
                        </Route>
                    </Routes>
                </HashRouter>
            </ErrorBoundary>
        </MobxProvider>
    );
});

export default Root;
