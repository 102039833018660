import {Navigate, Outlet, useLocation} from 'react-router-dom';
import React, {useState} from 'react';
import './ProtectedRoute.scss';
import Header from '../Header/Header';
import ClientModal from '../../../components/ClientModal/ClientModal';
import RealEstateModal from '../../../components/RealEstateModal/RealEstateModal';
import LeadModal from '../../../components/LeadModal/LeadModal';

type ProtectedRouteProps = {
    children?: JSX.Element;
    isAuthorized: boolean;
}
const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
    const [leadModalProps, setLeadModalProps] = useState({isOpen: false});
    const [clientModalProps, setClientModalProps] = useState({lead: null, isOpen: false});
    const [realEstateModalProps, setRealEstateModalProps] = useState({client: null, isOpen: false});

    const location = useLocation();
    if (!props.isAuthorized) {
        // Redirect them to the /sign page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/signin" state={{ from: location }} replace />;
    }
    return (<>
        {leadModalProps.isOpen
            && <LeadModal
                onClose={() => setLeadModalProps({isOpen: false})}/>
        }
        {clientModalProps.isOpen
            && <ClientModal
                lead={clientModalProps.lead}
                onClose={() => setClientModalProps({lead: null, isOpen: false})}/>
        }
        {realEstateModalProps.isOpen
            && <RealEstateModal
                client={realEstateModalProps.client}
                onClose={() => setRealEstateModalProps({client: null, isOpen: false})}/>
        }
        <Header isAuthorized={props.isAuthorized}/>
        <div className="content">
            <Outlet context={{setLeadModalProps, setClientModalProps, setRealEstateModalProps}}/>
        </div>
    </>);
}

export default ProtectedRoute;
