import React, {useContext, useEffect, useState} from 'react';
import './Leads.scss';
import {observer} from 'mobx-react';
import {
    Alert,
    Button,
    Card, CardBody, CardHeader,
    Col,
    Container, FormGroup,
    Input, Label,
    Row,
} from 'reactstrap';
import moment from 'moment';
import {Lead, leadsStoreContext} from '../../store/components/leadsStore';
import Select, {OnChangeValue} from 'react-select';
import {clone} from '../../utils/functions';
import {useOutletContext} from 'react-router-dom';
import {Province, provinceStoreContext} from '../../store/components/provinceStore';

type StatusOptions = {
    id: number;
    value: string;
    label: string;
    background: string;
    color: string;
}

type Filter = {
    status: StatusOptions;
    phone: string;
    fullName: string;
}

const options: StatusOptions[] = [
    { id: 0, value: 'all', label: 'All', background: '#ffffff', color: '#000000' },
    { id: 1, value: 'new', label: 'New', background: '#337ab7', color: '#ffffff' },
    { id: 2, value: 'contacted', label: 'Contacted', background: '#6c757d', color: '#ffffff' },
    { id: 3, value: 'callback', label: 'Callback', background: '#f0ad4e', color: '#ffffff' },
    { id: 4, value: 'closed', label: 'Closed', background: '#be6464', color: '#ffffff' },
    { id: 5, value: 'converted', label: 'Converted', background: '#3CB371', color: '#ffffff' },
];

const Leads = observer(() => {
    const {setLeadModalProps, setClientModalProps} = useOutletContext<any>();
    const leadsStoreCtx = useContext(leadsStoreContext);
    const provinceStoreCtx = useContext(provinceStoreContext);
    const [leads, setLeads] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [filterState, setFilterState] = useState<Filter>({
        status: options[0],
        phone: '',
        fullName: '',
    });
    const [provinces] = useState<Province[]>(provinceStoreCtx.getProvinces());
    useEffect(() => {
        const params = clone(filterState);
        params.statuses = params.status.id === 0 ? [] : [params.status.value];
        leadsStoreCtx.loadStore(params).then((result) => {
            setLeads(result);
            setLoading(false);
        });
    }, [leadsStoreCtx, leadsStoreCtx.modified, isLoading]);

    const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        setFilterState({...filterState, ...{[field]: event.target.value}});
    }

    const handleChangeStatus = (item: StatusOptions) => {
        setFilterState({...filterState, ...{status: item}});
        handleApplyFilter(null);
    }

    const handleMoveToClient = (lead: Lead) => {
        setClientModalProps({lead, isOpen: true});
    }

    const handleApplyFilter = (event: React.FormEvent | null) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        setLoading(true);
    }

    const handleResetFilter = () => {
        setFilterState({
            status: options[0],
            phone: '',
            fullName: '',
        });
        handleApplyFilter(null);
    }

    const handleChangeAgentNotes = async (event: React.FormEvent, lead: Lead) => {
        // @ts-ignore
        await leadsStoreCtx.update(lead.id, {...lead, ...{notes: event.target.value}});
        // @ts-ignore
        event.target.blur();
    }

    const handleChangeCity = async (event: React.FormEvent, lead: Lead) => {
        // @ts-ignore
        await leadsStoreCtx.update(lead.id, {...lead, ...{city: event.target.value || null}});
        // @ts-ignore
        event.target.blur();
    }

    const handleChangeLeadStatus = async (lead: Lead, item: StatusOptions) => {
        if (item.value === 'converted') {
            handleMoveToClient(lead);
        }
        if (item.value !== 'converted') {
            // @ts-ignore
            await leadsStoreCtx.update(lead.id, {...lead, ...{status: item.value}});
            setLoading(true);
        }
    }

    const handleChangeProvince = async (lead: Lead, item: OnChangeValue<unknown, false>) => {
        if (!item) {
            return;
        }
        // @ts-ignore
        await leadsStoreCtx.update(lead.id, {...lead, ...{province: item.name}});
        setLoading(true);
    }

    const handleOpenCreateLeadModal = () => setLeadModalProps({isOpen: true});

    return (
        <>
            <Container>
                <Row className={'leads'}>
                    <Col sm="12">
                        <Card body>
                            <form onSubmit={handleApplyFilter}>
                                <Row className="real-estates-filter align-items-end">
                                    <Col sm="2">
                                        <label htmlFor="fullName" className="form-label">Name</label>
                                        <Input
                                            bsSize="sm"
                                            id="fullName"
                                            name="fullName"
                                            type="text"
                                            value={filterState.fullName}
                                            onChange={(event) => handleChangeTextField(event, 'fullName')}
                                        />
                                    </Col>
                                    <Col sm="2">
                                        <label htmlFor="phone" className="form-label">Phone</label>
                                        <Input
                                            bsSize="sm"
                                            id="phone"
                                            name="phone"
                                            type="text"
                                            value={filterState.phone ? +filterState.phone : ''}
                                            onChange={(event) => handleChangeTextField(event, 'phone')}
                                        />
                                    </Col>
                                    <Col sm="4">
                                        <label htmlFor="status" className="form-label">Status</label>
                                        <Select
                                            styles={{
                                                // @ts-ignore
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    minHeight: '31px',
                                                    height: '31px',
                                                    // @ts-ignore
                                                    background: state.selectProps.value ? state.selectProps.value.background : '#ffffff',
                                                }),
                                                valueContainer: (provided, state) => ({
                                                    ...provided,
                                                    height: '31px',
                                                    padding: '0 6px',
                                                    // @ts-ignore
                                                    color: state.selectProps.value ? state.selectProps.value.color : '#ffffff'
                                                }),
                                                multiValue: (provided) => ({
                                                    ...provided,
                                                    marginTop: '0'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    margin: '0px',
                                                }),
                                                indicatorSeparator: state => ({
                                                    display: 'none',
                                                }),
                                                indicatorsContainer: (provided, state) => ({
                                                    ...provided,
                                                    height: '31px',
                                                }),
                                                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                                                    ...styles,
                                                    background: data.background,
                                                    color: data.color
                                                }),
                                                singleValue: (provided,state) => ({
                                                    ...provided,
                                                    // @ts-ignore
                                                    color: state.selectProps.value ? state.selectProps.value.color : '#000000'
                                                }),
                                            }}
                                            isClearable={false}
                                            options={options}
                                            id="status"
                                            // @ts-ignore
                                            onChange={handleChangeStatus}
                                            value={filterState.status}
                                            placeholder={''}
                                        />
                                    </Col>
                                    <Col className="col-auto">
                                        <Button hidden size="sm" type="submit" color="success" onClick={handleApplyFilter}>
                                            Apply
                                        </Button>
                                        <Button size="sm" type="button" onClick={handleResetFilter}>
                                            Reset
                                        </Button>
                                    </Col>
                                    <Col style={{textAlign: 'right'}}>
                                        <Button size="sm" color={'primary'} type={'button'} onClick={handleOpenCreateLeadModal}>Create lead</Button>
                                    </Col>
                                </Row>
                            </form>
                            {
                                isLoading && <div>Loading...</div>
                            }
                            {
                                !isLoading && (!leads || !leads.length) ? <div>
                                        <Alert color="warning">
                                            No data for display
                                        </Alert>
                                    </div>
                                    :
                                    !isLoading && <>
                                    {leads.map((lead: Lead) => {
                                        return (<Card key={lead.id} style={{marginBottom: '1rem', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px', overflow: 'hidden'}}>
                                            <CardHeader style={{background: '#f1f1f1'}}>
                                                <Row style={{alignItems: 'center'}}>
                                                    <Col>
                                                        <div>
                                                            <b>{lead.fullName}</b>
                                                        </div>
                                                        <span className="date"><i className="icon-date"/> {lead.createdAt ? moment(lead.createdAt).format('DD MMM YYYY, hh:mm') : ''}</span>
                                                    </Col>
                                                    <Col className="col-2">
                                                        <Select
                                                            isDisabled={lead.status === 'converted'}
                                                            styles={{
                                                                // @ts-ignore
                                                                control: (provided, state) => ({
                                                                    ...provided,
                                                                    minHeight: '31px',
                                                                    height: '31px',
                                                                }),
                                                                valueContainer: (provided, state) => ({
                                                                    ...provided,
                                                                    height: '31px',
                                                                    padding: '0 6px',
                                                                }),
                                                                multiValue: (provided) => ({
                                                                    ...provided,
                                                                    marginTop: '0'
                                                                }),
                                                                input: (provided, state) => ({
                                                                    ...provided,
                                                                    margin: '0px',
                                                                }),
                                                                indicatorSeparator: state => ({
                                                                    display: 'none',
                                                                }),
                                                                indicatorsContainer: (provided, state) => ({
                                                                    ...provided,
                                                                    height: '31px',
                                                                })
                                                            }}
                                                            isClearable={false}
                                                            options={provinces}
                                                            id={`${lead.id}_province`}
                                                            onChange={(event) => handleChangeProvince(lead, event)}
                                                            value={provinces.find((province) => province.name === lead.province)}
                                                            placeholder={''}
                                                            getOptionValue={(option) => option.name.toString()}
                                                            getOptionLabel={(option) => option.name}
                                                        />
                                                    </Col>
                                                    <Col className="col-2">
                                                        <Input
                                                            disabled={lead.status === 'converted'}
                                                            bsSize="sm"
                                                            id="city"
                                                            name="city"
                                                            type="text"
                                                            onBlur={(event) => handleChangeCity(event, lead)}
                                                            onKeyDown={(event) => {
                                                                if(event.code === 'Enter' && !event.shiftKey) {
                                                                    event.preventDefault();
                                                                    handleChangeCity(event, lead).then(() => {});
                                                                }
                                                            }}
                                                            defaultValue={lead.city ? lead.city : ''}
                                                            placeholder={'city'}
                                                        />
                                                    </Col>
                                                    <Col className="col-2">
                                                        <Select
                                                            isDisabled={lead.status === 'converted'}
                                                            styles={{
                                                                // @ts-ignore
                                                                control: (provided, state) => ({
                                                                    ...provided,
                                                                    minHeight: '31px',
                                                                    height: '31px',
                                                                    // @ts-ignore
                                                                    background: state.selectProps.value ? state.selectProps.value.background : '#ffffff',
                                                                    // @ts-ignore
                                                                    color: state.selectProps.value ? state.selectProps.value.color : '#000000'
                                                                }),
                                                                valueContainer: (provided, state) => ({
                                                                    ...provided,
                                                                    height: '31px',
                                                                    padding: '0 6px',
                                                                }),
                                                                multiValue: (provided) => ({
                                                                    ...provided,
                                                                    marginTop: '0'
                                                                }),
                                                                input: (provided, state) => ({
                                                                    ...provided,
                                                                    margin: '0px',
                                                                }),
                                                                indicatorSeparator: state => ({
                                                                    display: 'none',
                                                                }),
                                                                indicatorsContainer: (provided, state) => ({
                                                                    ...provided,
                                                                    height: '31px',
                                                                }),
                                                                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                                                                    ...styles,
                                                                    background: data.background,
                                                                    color: data.color
                                                                }),
                                                                singleValue: (provided,state) => ({
                                                                    ...provided,
                                                                    // @ts-ignore
                                                                    color: state.selectProps.value ? state.selectProps.value.color : '#000000'
                                                                }),
                                                            }}
                                                            isClearable={false}
                                                            options={options.filter((status) => status.value !== 'all')}
                                                            id={lead.id + '_status'}
                                                            // @ts-ignore
                                                            onChange={(event) => handleChangeLeadStatus(lead, event)}
                                                            value={options.find((status) => status.value === lead.status)}
                                                            placeholder={''}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardHeader>
                                            <CardBody style={{background: '#f9f9f9', overflow: 'hidden'}}>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>
                                                                <i className="icon-user-tie"/>Type
                                                            </Label>
                                                            <div>
                                                                {lead.type || '-'}
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>
                                                                <i className="icon-phone"/>Phone
                                                            </Label>
                                                            <div>
                                                                {lead.phoneCode} {lead.phone}
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>
                                                                <i className="icon-main"/>Email
                                                            </Label>
                                                            <div>
                                                                {lead.email || '-'}
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>
                                                                <i className="icon-home"/>Real estate type
                                                            </Label>
                                                            <div>
                                                                {lead.realEstateType || '-'}
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>
                                                                <i className="icon-coin-euro"/>Price
                                                            </Label>
                                                            <div>
                                                                {lead.price  || '-'}
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                {lead.description && <Row>
                                                    <Col>{lead.description}</Col>
                                                </Row>}
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for={lead.id + '_agent-notes'}>
                                                                <i className="icon-edit-folder"/> Notes
                                                            </Label>
                                                            <div>
                                                                <Input
                                                                    id={lead.id + '_agent-notes'}
                                                                    name="text"
                                                                    type="textarea"
                                                                    placeholder={'Agent notes...'}
                                                                    onBlur={(event) => handleChangeAgentNotes(event, lead)}
                                                                    onKeyDown={(event) => {
                                                                        if(event.code === 'Enter' && !event.shiftKey) {
                                                                            event.preventDefault();
                                                                            handleChangeAgentNotes(event, lead).then(() => {});
                                                                        }
                                                                    }}
                                                                    defaultValue={lead.notes ? lead.notes : ''}
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>)
                                    })}
                                    </>
                            }
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
})

export default Leads;
